import React, { useState } from "react";
import { getReadableDate } from "../../utils/datetime";
import { Feather as Icon } from 'react-web-vector-icons';
import { useDispatch, useSelector } from "react-redux";
import { deleteSpecialHoursRequested } from "../../actions/store";
import { DefaultButton } from "../buttons/DefaultButton";
import { ActivityIndicator } from "../loading/ActivityIndicator";

export const SpecialHoursList = () => {
  const specialHours = useSelector(state => state.store.specialHours);
  const dispatch = useDispatch();

  return (
    <div className="specialHoursList">
      {specialHours && specialHours.length === 0 && <span className="emptyText">Keine Sonderfälle vorhanden.</span>}
      {specialHours ? specialHours.map(d => <SpecialHoursEntry key={d.id} {...d} dispatch={dispatch} />) : <ActivityIndicator />}
    </div>
  )
}

const SpecialHoursEntry = ({ id, date, openTime, closeTime, closed, reason, dispatch }) => {
  const [deletePressed, setDeletePressed] = useState(false);
  const [loading, setLoading] = useState(false);

  const deleteSpecialHour = () => {
    setLoading(true);
    dispatch(deleteSpecialHoursRequested(id, () => {
      setLoading(false);
    }))
  }

  return (
    <div className="specialHoursEntry">
      <div className="top">
        <span className="date">{getReadableDate(date)}:</span>
        <span className="openValue">{closed ? `Geschlossen` : `Geöffnet von ${openTime} bis ${closeTime}`}</span>
        {!deletePressed && <div className="deleteOption" onClick={() => setDeletePressed(true)}>
          <Icon name="x" color='var(--onSurfaceStrong)' size={20} />
        </div>}
        {deletePressed && <DefaultButton text="Löschen" loading={loading} isDelete={true} isFlat={true} onPress={() => deleteSpecialHour()} />}
      </div>
      {reason ? <div className="bottom">
        <span className="reason">{reason}</span>
      </div> : null}
    </div>
  )
}