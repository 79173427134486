import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { storeLoginSucceeded } from "../actions/authentication";
import { ActivityIndicator } from "../components/loading/ActivityIndicator";

export const AutoLogin = () => {
  const [isInvalid, setIsInvalid] = useState(false);
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const token = getCookie('store_token');
    if (!token) {
      setIsInvalid(true);
    } else {
      dispatch(storeLoginSucceeded(token));
      setTimeout(() => {
        setSuccess(true);
      }, 50);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getCookie = (name) => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  if (success) return <Redirect to={{ pathname: "/orders" }} />
  return (
    <div className="maintenance">
      {!isInvalid && <ActivityIndicator />}
      {isInvalid && <span className="extraLarge medium">Ungültige Anfrage</span>}
    </div>
  )
}