import React, { useEffect, useState } from "react";
import { BasicInput } from "./BasicInput";
import { CheckBox } from "./CheckBox";

const initialData = [
  { dayOfWeek: 0, openTime: "07:00", closeTime: "17:00", closed: false },
  { dayOfWeek: 1, openTime: "07:00", closeTime: "17:00", closed: false },
  { dayOfWeek: 2, openTime: "07:00", closeTime: "17:00", closed: false },
  { dayOfWeek: 3, openTime: "07:00", closeTime: "17:00", closed: false },
  { dayOfWeek: 4, openTime: "07:00", closeTime: "17:00", closed: false },
  { dayOfWeek: 5, openTime: "07:00", closeTime: "17:00", closed: false },
  { dayOfWeek: 6, openTime: "07:00", closeTime: "17:00", closed: false }
];

export const OpeningHoursInput = ({ data, onChange }) => {
  const weekdays = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"];
  // Convert because backend sends 0/1 but expects true/false
  const [newData, setNewData] = useState([...data.map(d => ({ ...d, closed: d.closed ? true : false }))] || initialData);

  useEffect(() => {
    if (onChange) onChange(newData);
  }, [newData]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeValue = (dow, varName, varValue) => {
    const nd = newData;
    nd[dow] = { ...nd[dow], [varName]: varValue };
    setNewData([...nd]);
  }

  return (
    <div className="openingHours">
      {newData.map(d => {
        const { dayOfWeek, openTime, closeTime, closed } = d;
        return (
          <div key={dayOfWeek} className={`openingHour${closed ? ' closed' : ''}`}>
            <CheckBox value={closed} onChange={(c) => changeValue(dayOfWeek, "closed", c)} />
            <span className="dayName" onClick={() => changeValue(dayOfWeek, "closed", !closed)}>{weekdays[dayOfWeek]}:</span>
            {closed ?
              <span>Geschlossen</span>
              :
              <React.Fragment>
                <BasicInput value={openTime} disabled={closed} onChange={(e) => changeValue(dayOfWeek, "openTime", e.target.value)} />
                <span className="until">bis</span>
                <BasicInput value={closeTime} disabled={closed} onChange={(e) => changeValue(dayOfWeek, "closeTime", e.target.value)} />
              </React.Fragment>}
          </div>
        )
      })}
    </div>
  )
}