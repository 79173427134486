import React, { useEffect, useState } from "react";
import { BasicInput } from "../inputs/BasicInput";

export const NutritionTable = ({ editMode, nutritionFacts, onChange }) => {
  const [newNutritionFacts, setNewNutritionFacts] = useState({
    energyContent: 0,
    calorificValue: 0,
    fat: 0,
    saturatedFattyAcids: 0,
    carbohydrates: 0,
    sugar: 0,
    protein: 0,
    salt: 0
  });

  const setNutritionField = (fieldName, fieldValue) => {
    setNewNutritionFacts({ ...newNutritionFacts, [fieldName]: fieldValue });
  }

  useEffect(() => {
    if (nutritionFacts) {
      const { enabled, ...rest } = nutritionFacts;
      setNewNutritionFacts({ ...rest })
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (onChange) {
      let parsedNutritionFields = {};
      Object.keys(newNutritionFacts).forEach(f => {
        parsedNutritionFields[f] = parseFloat(newNutritionFacts[f]);
      });
      onChange(parsedNutritionFields);
    }
  }, [newNutritionFacts]); // eslint-disable-line react-hooks/exhaustive-deps

  const replaceDotWithComma = (input) => {
    return input.toFixed(1).replace('.', ',');
  }

  return editMode ? (
    <table className="nutritionTable edit" border="0">
      <thead>
        <tr>
          <th>Nährwerte</th>
          <th>pro 100g</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><span>Energie</span></td>
          <td><BasicInput type="number" placeholder="" value={newNutritionFacts.energyContent} onChange={(e) => setNutritionField("energyContent", e.target.value)} /><span>kcal</span></td>
        </tr>
        <tr>
          <td></td>
          <td><BasicInput type="number" placeholder="" value={newNutritionFacts.calorificValue} onChange={(e) => setNutritionField("calorificValue", e.target.value)} /><span>kJ</span></td>
        </tr>
        <tr>
          <td><span>Fett</span></td>
          <td><BasicInput type="number" placeholder="" value={newNutritionFacts.fat} onChange={(e) => setNutritionField("fat", e.target.value)} /><span>g</span></td>
        </tr>
        <tr>
          <td><span className="indent">davon gesättigte Fettsäuren</span></td>
          <td><BasicInput type="number" placeholder="" value={newNutritionFacts.saturatedFattyAcids} onChange={(e) => setNutritionField("saturatedFattyAcids", e.target.value)} /><span>g</span></td>
        </tr>
        <tr>
          <td><span>Kohlenhydrate</span></td>
          <td><BasicInput type="number" placeholder="" value={newNutritionFacts.carbohydrates} onChange={(e) => setNutritionField("carbohydrates", e.target.value)} /><span>g</span></td>
        </tr>
        <tr>
          <td><span className="indent">davon Zucker</span></td>
          <td><BasicInput type="number" placeholder="" value={newNutritionFacts.sugar} onChange={(e) => setNutritionField("sugar", e.target.value)} /><span>g</span></td>
        </tr>
        <tr>
          <td><span>Eiweiß</span></td>
          <td><BasicInput type="number" placeholder="" value={newNutritionFacts.protein} onChange={(e) => setNutritionField("protein", e.target.value)} /><span>g</span></td>
        </tr>
        <tr>
          <td><span>Salz</span></td>
          <td><BasicInput type="number" placeholder="" value={newNutritionFacts.salt} onChange={(e) => setNutritionField("salt", e.target.value)} /><span>g</span></td>
        </tr>
      </tbody>
    </table>
  ) : (
    <table className="nutritionTable" border="0">
      <thead>
        <tr>
          <th>Nährwerte</th>
          <th>pro 100g</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><span>Energie</span></td>
          <td><span>{nutritionFacts.energyContent} kcal / {nutritionFacts.calorificValue} kJ</span></td>
        </tr>
        <tr>
          <td><span>Fett</span></td>
          <td><span>{replaceDotWithComma(nutritionFacts.fat)} g</span></td>
        </tr>
        <tr>
          <td><span className="indent">davon gesättigte Fettsäuren</span></td>
          <td><span>{replaceDotWithComma(nutritionFacts.saturatedFattyAcids)} g</span></td>
        </tr>
        <tr>
          <td><span>Kohlenhydrate</span></td>
          <td><span>{replaceDotWithComma(nutritionFacts.carbohydrates)} g</span></td>
        </tr>
        <tr>
          <td><span className="indent">davon Zucker</span></td>
          <td><span>{replaceDotWithComma(nutritionFacts.sugar)} g</span></td>
        </tr>
        <tr>
          <td><span>Eiweiß</span></td>
          <td><span>{replaceDotWithComma(nutritionFacts.protein)} g</span></td>
        </tr>
        <tr>
          <td><span>Salz</span></td>
          <td><span>{replaceDotWithComma(nutritionFacts.salt)} g</span></td>
        </tr>
      </tbody>
    </table>
  )
}