export const companyLookupRequested = (urlIdentifier, handleResponse) => ({
  type: "COMPANY_LOOKUP_REQUESTED",
  urlIdentifier,
  handleResponse
});

export const setCompanyInfos = (id, name, colors, urlIdentifier) => ({
  type: "SET_COMPANY_INFOS",
  id,
  name,
  colors,
  urlIdentifier
});

export const setStoreId = (id) => ({
  type: "SET_STORE_ID",
  id
});