import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerOrderListRequested } from "../../actions/customer";
// import { ActivityIndicator } from "../loading/ActivityIndicator";
import { VariableDisplay } from "../text/VariableDisplay";
import { getReadableDateTime } from "../../utils/datetime";
import { convertToCurrency } from "../../utils/conversion";
import { orderStateInfo } from "../../data/orderStates";
import { DetailActionButton } from "../buttons/DetailActionButton";

export const CustomerDetails = ({ history }) => {
  // const selectedCustomerId = parseInt(match.params.selectedCustomerId);
  const hasCustomerList = useSelector(state => state.customer.customers ? true : false);
  // const customer = useSelector(state => state.customer.customers ? state.customer.customers.filter(c => c.id === selectedCustomerId)[0] : null);
  const customer = useSelector(state => state.customer.selectedCustomer);
  const dispatch = useDispatch();

  useEffect(() => {
    if (hasCustomerList && customer) dispatch(getCustomerOrderListRequested(customer.id));
  }, [customer && customer.id, hasCustomerList]); // eslint-disable-line react-hooks/exhaustive-deps

  return customer ? (
    <React.Fragment>
      <div className="detailHeader">
        <div className="detailHeaderTitle">
          <span className="big">Kundendetails</span>
        </div>
        <div className="detailHeaderActions">
          <DetailActionButton icon="x" onPress={() => history.push("/customers")} />
        </div>
      </div>
      <div className="detailContent">
        <div className="product">
          <div className="productInfoContainer">
            <VariableDisplay label="Vorname" value={customer.firstName} marginBottom={true} />
            <VariableDisplay label="Nachname" value={customer.lastName} marginBottom={true} />
            <VariableDisplay label="E-Mail Adresse" value={customer.email} marginBottom={true} />
            <VariableDisplay label="Telefon" value={customer.phone} marginBottom={true} />
            <VariableDisplay label="Letzte 10 Bestellungen" value={""} marginBottom={true} />
            
            {customer.orders && <div className="customerOrderContainer">
              <table border="0">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Bestellungsstatus</th>
                    <th>Abhol-/Lieferdatum</th>
                    <th>Summe</th>
                  </tr>
                </thead>
                <tbody>
                  {customer.orders.map(o => <CustomerOrderEntry key={o.id} {...o} history={history} />)}
                </tbody>
              </table>
            </div>}
          </div>
          {/* <div className="productImageContainer">
          </div> */}
        </div>
      </div>
    </React.Fragment>
  ) : <span className="emptyText">Bitte wählen Sie eine(n) Kunden/in aus</span>;
}

const CustomerOrderEntry = ({ history, id, orderStatus, executionTime, sum }) => {

  const openCustomerOrder = () => {
    history.push(`/order/${id}`);
  }

  return (
    <tr onClick={() => openCustomerOrder()}>
      <td><span>#{id}</span></td> 
      <td><span>{orderStateInfo[orderStatus].shortLabel}</span></td> 
      <td><span>{getReadableDateTime(executionTime)}</span></td> 
      <td><span>{convertToCurrency(sum)}</span></td> 
    </tr>
  )
}