import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CustomerEntry } from "../components/customer/CustomerEntry";
import { ActivityIndicator } from "../components/loading/ActivityIndicator";
import { Feather as Icon } from 'react-web-vector-icons';
import { Route } from "react-router-dom";
import { CustomerDetails } from "../components/customer/CustomerDetails";
import { retrieveCustomerListRequested } from "../actions/customer";
import "./../style/routes/Customer.scss";

let requestPending = false;

export const Customers = () => {
  const customers = useSelector(state => state.customer.customers);
  const [searchString, setSearchString] = useState("");
  const [lastId, setLastId] = useState(null);
  const [bottomReached, setBottomReached] = useState(false);
  const listRef = useRef();
  const dispatch = useDispatch();

  const customerList = customers && customers.map(c => <CustomerEntry key={c.id} {...c} />);

  useEffect(() => {
    dispatch(retrieveCustomerListRequested(searchString === "" ? null : searchString, null, false, handleResponse));
  }, [searchString]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleResponse = (customerAmount, plastId) => {
    if (customerAmount < 20) setBottomReached(true);
    else {
      // If first result page isn't long enough to enable scrolling
      if (listRef.current && listRef.current.scrollHeight <= listRef.current.clientHeight) {
        setTimeout(() => { // Timeout is required for "takeLatest" saga effect because it cancels itself and throws an error otherwise (prob. same thread)
          dispatch(retrieveCustomerListRequested(searchString === "" ? null : searchString, plastId, true, handleResponse));
        });
      } else {
        setLastId(plastId);
      }
    }
    requestPending = false;
  }

  const handleScroll = (e) => {
    if (requestPending || bottomReached) return;
    const { offsetHeight, scrollTop, scrollHeight } = e.target;
    if (offsetHeight + scrollTop + 200 >= scrollHeight) {
      requestPending = true;
      dispatch(retrieveCustomerListRequested(searchString === "" ? null : searchString, lastId, true, handleResponse));
    }
  }

  return (
    <div className="appContent">
      <div className="pageHeader">
        <div className="pageHeaderLeft">
          <span className="pageTitle">Kundenverwaltung</span>
          <span className="pageDescription">Ermöglicht die Verwaltung von Kunden</span>
        </div>
      </div>
      <div className="pageContent">
        <div className="card maxHeight leftList">
          <div className="searchHeader">
            <Icon name="search" color='var(--onSurfaceStrong)' size={20} />
            <input type="text" placeholder="Nach Namen filtern" value={searchString} onChange={e => setSearchString(e.target.value)} />
          </div>
          <div className="listContent hasSearchHeader" ref={listRef} onScroll={handleScroll}>
            {customers !== null ? customerList : <ActivityIndicator />}
            {customers !== null && !bottomReached && <ActivityIndicator />}
            {(customers && customers.length === 0) && <span className="emptyText">Keine Kunden gefunden</span>}
          </div>
        </div>
        <div className="card maxHeight">
          {/* <Route exact path="/customers" render={() => <span className="emptyText">Bitte wählen Sie eine(n) Kunden/in aus</span>} /> */}
          {/* <Route exact path="/customer/:selectedCustomerId" component={CustomerDetails} /> */}
          <Route exact path="/customers" component={CustomerDetails} />
        </div>
      </div>
    </div>
  )
}