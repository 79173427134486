const initialState = {
  customers: null,
  selectedCustomer: null
};

export const customer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_CUSTOMER_ORDER_LIST_SUCCEEDED": {
      if (!state.selectedCustomer || !state.selectedCustomer.id === action.customerId) return state;
      return {
        ...state,
        selectedCustomer: {
          ...state.selectedCustomer,
          orders: action.orderList
        }
      }
    }
    case "SELECT_CUSTOMER": {
      const index = state.customers.findIndex(s => s.id === action.customerId);
      if (index === -1 || (state.selectedCustomer && state.selectedCustomer.id === action.customerId)) return state;
      const { firstName, lastName, email, phone } = state.customers[index];
      return {
        ...state,
        selectedCustomer: {
          id: action.customerId, firstName, lastName, email, phone
        }
      }
    }
    case "RETRIEVE_CUSTOMER_LIST_SUCCEEDED": {
      const { customerList, append } = action;
      if (append) {
        return {
          ...state,
          customers: [
            ...state.customers, 
            ...customerList
          ]
        }
      } else {
        return {
          ...state,
          customers: action.customerList
        }
      }
    }
    default:
      return state;
  }
};
