import openSocket from "socket.io-client";
import { addGlobalMessageRequested, setNetworkOffline } from "./actions/general";
import { insertNewOrder, updateOrderStateSucceeded } from "./actions/order";
import { notificationTypes } from "./data/notificatonTypes";
import { getReadableUntilDateTime } from "./utils/datetime";

let socket;
export const listenForSocketEvents = (token, dispatch) => {
  socket = openSocket(process.env.REACT_APP_SOCKET_URL);

  socket.on("connect", () => {
    // dispatch(setOnlineStatus(true));
    console.log("%c Connection established!", "color:lightgreen");
    dispatch(setNetworkOffline(false));
    socket.emit("joinStoreRequest", { token });
  });

  socket.on("joinStoreFailed", error => {
    dispatch(addGlobalMessageRequested({ text: error.errorMsg, type: notificationTypes.ERROR }));
  });

  socket.on("disconnect", () => {
    // dispatch(setOnlineStatus(false));
    dispatch(setNetworkOffline(true));
    console.log("%c Connection lost!", "color:red");
  });

  /* ORDER */
  socket.on("newOrder", (orderData) => {
    const { id, pickupCode, executionTime, currentOrderState } = orderData;
    dispatch(insertNewOrder({ id, pickupCode, executionTime, currentOrderState: parseInt(currentOrderState) }));
    dispatch(addGlobalMessageRequested({ 
      text: `#${id} - Abholung ${getReadableUntilDateTime(executionTime)}`, 
      type: notificationTypes.NEW_ORDER, 
      orderId: id,
      duration: 30000
    }));
    const audio = new Audio('/new_order.mp3');
    audio.play().catch(() => console.log("Audio could not be played."));
  });

  socket.on("updateOrderStatus", (orderData) => {
    const { orderId, status, reason } = orderData;
    dispatch(updateOrderStateSucceeded(orderId, status, reason));
  });
}

export const disconnectSocket = () => {
  if (socket) socket.close();
}