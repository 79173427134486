export const storeLoginRequested = (username, password, companyId, handleResult) => ({
  type: "STORE_LOGIN_REQUESTED",
  username, password, companyId, handleResult
});

export const storeLoginSucceeded = (token) => ({
  type: "STORE_LOGIN_SUCCEEDED",
  token
});

export const logout = () => ({
  type: "LOGOUT"
});

export const setFCMToken = (token) => ({
  type: "SET_FCM_TOKEN", token
});
export const deleteFCMToken = () => ({
  type: "DELETE_FCM_TOKEN"
});