const initialState = {
  loggedIn: false,
  token: null,
  fcmToken: null
};

export const authentication = (state = initialState, action) => {
  switch (action.type) {
    case "DELETE_FCM_TOKEN": {
      return {
        ...state,
        fcmToken: null
      }
    }
    case "SET_FCM_TOKEN": {
      return {
        ...state,
        fcmToken: action.token
      }
    }
    case "STORE_LOGIN_SUCCEEDED": {
      return {
        ...state,
        loggedIn: true,
        token: action.token
      }
    }
    default:
      return state;
  }
};
