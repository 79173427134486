import { DateTime } from "luxon";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getReadableDateTimeVariation } from "../../utils/datetime";

export const DataAgeDisplay = () => {
    const dataIntegrityGuaranteed = useSelector(state => state.general.dataIntegrityGuaranteed);
    const networkOffline = useSelector(state => state.general.networkOffline);
    const [lastUpdateTime, setLastUpdateTime] = useState(DateTime.now());

    useEffect(() => {
        const refreshInterval = setInterval(() => {
            if (dataIntegrityGuaranteed && !networkOffline) setLastUpdateTime(DateTime.now());
        }, 5000);
        return () => clearInterval(refreshInterval);
    }, [dataIntegrityGuaranteed, networkOffline]);

    const noLongerUpdating = !(dataIntegrityGuaranteed && !networkOffline);
    return (
        <div className="dataAgeDisplay">
            <span className={`small ${noLongerUpdating ? "error" : ""}`}>{noLongerUpdating ? "Veralteter Datenstand" : "Datenstand"}</span>
            <span className="value big">{getReadableDateTimeVariation(lastUpdateTime)}</span>
        </div>
    )
}