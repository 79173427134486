import { put, takeEvery, call } from "redux-saga/effects";
import { storeLoginSucceeded } from "../actions/authentication";
import { setStoreId } from "../actions/company";
import { addGlobalMessageRequested } from "../actions/general";
import { statusCodes } from "../api/config";
import { basicAPIRequest } from "../api/requests";
import { notificationTypes } from "../data/notificatonTypes";

export function* watchAllAuthenticationActions() {
  yield takeEvery("STORE_LOGIN_REQUESTED", beginStoreLogin);
}

function* beginStoreLogin(action) {
  try {
    const { username, password, companyId, handleResult } = action;
    const request = yield call(() => basicAPIRequest("/store/login", { username, password, companyId }));

    switch (request.statusCode) {
      case statusCodes.SUCCESS: {
        handleResult(true);
        yield put(setStoreId(request.result.storeId));
        yield put(storeLoginSucceeded(request.result.token));
        break;
      }
      case statusCodes.AUTH_ERROR: {
        handleResult(false);
        // yield put(addGlobalMessageRequested({ text: "Die angegebenen Anmeldedaten sind ungültig. Bitte versuche es erneut.", duration: 3000, type: notificationTypes.ERROR }));
        break;
      }
      case statusCodes.INVALID_PARAMS: {
        handleResult(false);
        break;
      }
      default: {
        handleResult(false);
        yield put(addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR }));
        break;
      }
    }

  } catch (e) {
    console.log(e.message);
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}