import { put, takeEvery, select, call } from "redux-saga/effects";
import { addGlobalMessageRequested } from "../actions/general";
import { retrieveOpenOrdersSucceeded, retrievePackedOrdersSucceeded, retrieveDoneOrdersSucceeded, getOrderDetailsSucceeded } from "../actions/order";
import { authAPIRequest } from "../api/requests";
import { notificationTypes } from "../data/notificatonTypes";
import { genericHandleResult } from "./sagaHelper";

export function* watchAllOrderActions() {
  yield takeEvery("RETRIEVE_OPEN_ORDERS_REQUESTED", beginRetrieveOpenOrderList);
  yield takeEvery("RETRIEVE_PACKED_ORDERS_REQUESTED", beginRetrievePackedOrderList);
  yield takeEvery("RETRIEVE_DONE_ORDERS_REQUESTED", beginRetrieveDoneOrderList);
  yield takeEvery("GET_ORDER_DETAILS_REQUESTED", beginGetOrderDetails);
  yield takeEvery("UPDATE_ORDER_STATE_REQUESTED", beginUpdateOrderState);
}

function* beginRetrieveOpenOrderList() {
  try {
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/store/get-open-orders", {}, token));
    // yield delay(100);
    // const request = { statusCode: 0, result: [] };

    // const request = {
    //   statusCode: 0, result: [{
    //     id: 422,
    //     pickupCode: "B228",
    //     pickupTime: "2021-02-26T15:30:00.000Z",
    //     currentOrderState: 2
    //   }, {
    //     id: 424,
    //     pickupCode: "A601",
    //     pickupTime: "2021-02-27T10:30:00.000Z",
    //     currentOrderState: 2
    //   }, {
    //     id: 434,
    //     pickupCode: "HG2A",
    //     pickupTime: "2021-02-28T09:30:00.000Z",
    //     currentOrderState: 2
    //   }]
    // }

    yield genericHandleResult(
      request.statusCode,
      retrieveOpenOrdersSucceeded(request.result),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );

    // yield delay(3000);
    // yield put(insertNewOrder({
    //   id: 4,
    //   pickupCode: "9999",
    //   pickupTime: "2021-02-01T09:50:00.000Z",
    //   currentOrderState: 2
    // }))
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginRetrievePackedOrderList() {
  try {
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/store/get-packed-orders", {}, token));

    // yield delay(100);
    // const request = { statusCode: 0, result: [] };

    // const request = {
    //   statusCode: 0, result: [{
    //     id: 1,
    //     pickupCode: "A603",
    //     pickupTime: "2021-02-01T09:50:00.000Z",
    //     currentOrderState: 3
    //   }, {
    //     id: 2,
    //     pickupCode: "A604",
    //     pickupTime: "2021-02-01T09:50:00.000Z",
    //     currentOrderState: 3
    //   }, {
    //     id: 3,
    //     pickupCode: "A605",
    //     pickupTime: "2021-02-01T09:50:00.000Z",
    //     currentOrderState: 3
    //   }]
    // }

    yield genericHandleResult(
      request.statusCode,
      retrievePackedOrdersSucceeded(request.result),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginRetrieveDoneOrderList(action) {
  try {
    const { lastId, handleResponse } = action;
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/store/get-completed-orders", { lastId }, token));

    // yield delay(100);
    // const request = { statusCode: 0, result: [] };

    if (request.result && request.result.length > 0) handleResponse(request.result.length, request.result[request.result.length - 1].id);
    else handleResponse(0, null);

    yield genericHandleResult(
      request.statusCode,
      retrieveDoneOrdersSucceeded(request.result),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginGetOrderDetails(action) {
  try {
    const { orderId } = action;
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/store/get-order-details", { orderId }, token));

    // yield delay(100);

    // const request = {
    //   statusCode: 0, result: {
    //     id: orderId,
    //     pickupCode: "B228",
    //     pickupTime: "2021-02-22T15:30:00.000Z",
    //     // orderStateHistory: [1, 2, 4, 5],
    //     orderStateHistory: [1, 2],
    //     customer: {
    //       firstName: "Max",
    //       lastName: "Mustermann",
    //       phoneNumber: "+49 123 456 77"
    //     },
    //     products: [{
    //       id: 1,
    //       name: "Ciabatta",
    //       price: 2.2,
    //       amount: 2
    //     }, {
    //       id: 2,
    //       name: "Zwiebelbaguette",
    //       price: 3.4,
    //       amount: 1
    //     }]
    //   }
    // }

    yield genericHandleResult(
      request.statusCode,
      getOrderDetailsSucceeded(orderId, request.result),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginUpdateOrderState(action) {
  try {
    const { orderId, newOrderState, reason, setLoading } = action;
    setLoading(true);
    // const { orderId, setLoading } = action;

    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/store/update-order-status", { orderId, status: newOrderState, reason: reason ? reason : null }, token));

    // yield delay(1000);

    // const request = {
    //   statusCode: 0, result: {
    //     status: 4
    //   }
    // }

    setLoading(false);

    yield genericHandleResult(
      request.statusCode,
      null, // updateOrderStateSucceeded(orderId, request.result ? request.result.status : null),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}