export const getStoreDetailsRequested = () => ({
  type: "GET_STORE_DETAILS_REQUESTED"
});

export const getStoreDetailsSucceeded = (storeDetails) => ({
  type: "GET_STORE_DETAILS_SUCCEEDED",
  storeDetails
});

export const updateOpeningHoursRequested = (openingHours, handleResponse) => ({
  type: "UPDATE_OPENING_HOURS_REQUESTED",
  openingHours, handleResponse
});

export const updateOpeningHoursSucceeded = (openingHours) => ({
  type: "UPDATE_OPENING_HOURS_SUCCEEDED",
  openingHours
});

export const updatePhoneNumberRequested = (phoneNumber, handleResponse) => ({
  type: "UPDATE_PHONE_NUMBER_REQUESTED",
  phoneNumber, handleResponse
});

export const updatePhoneNumberSucceeded = (phoneNumber) => ({
  type: "UPDATE_PHONE_NUMBER_SUCCEEDED",
  phoneNumber
});

export const deleteSpecialHoursRequested = (id, handleResponse) => ({
  type: "DELETE_SPECIAL_HOURS_REQUESTED",
  id, handleResponse
});

export const deleteSpecialHoursSucceeded = (id) => ({
  type: "DELETE_SPECIAL_HOURS_SUCCEEDED",
  id
});

export const addSpecialHoursRequested = (specialHoursData, handleResponse) => ({
  type: "ADD_SPECIAL_HOURS_REQUESTED",
  specialHoursData, handleResponse
});

export const addSpecialHoursSucceeded = (specialHoursData) => ({
  type: "ADD_SPECIAL_HOURS_SUCCEEDED",
  specialHoursData
});

export const updateOrderTimeSettingsRequested = (orderTimeSettings, handleResponse) => ({
  type: "UPDATE_ORDER_TIME_SETTINGS_REQUESTED",
  orderTimeSettings, handleResponse
});

export const updateOrderTimeSettingsSucceeded = (orderTimeSettings) => ({
  type: "UPDATE_ORDER_TIME_SETTINGS_SUCCEEDED",
  orderTimeSettings
});

export const getStoreTagsRequested = () => ({
  type: "GET_STORE_TAGS_REQUESTED"
});

export const getStoreTagsSucceeded = (availableStoreTags, activeStoreTags) => ({
  type: "GET_STORE_TAGS_SUCCEEDED",
  availableStoreTags, activeStoreTags
});

export const setStoreTagRequested = (storeTag, handleResponse) => ({
  type: "SET_STORE_TAG_REQUESTED",
  storeTag, handleResponse
});

export const setStoreTagSucceeded = (storeTag) => ({
  type: "SET_STORE_TAG_SUCCEEDED",
  storeTag
});

export const removeStoreTagRequested = (id, handleResponse) => ({
  type: "REMOVE_STORE_TAG_REQUESTED",
  id, handleResponse
});

export const removeStoreTagSucceeded = (id) => ({
  type: "REMOVE_STORE_TAG_SUCCEEDED",
  id
});