export const notificationTypes = {
  NEW_ORDER: 1,
  INFO: 2,
  WARNING: 3,
  ERROR: 4
}

export const notificationTypeInfos = {
  [notificationTypes.NEW_ORDER]: {
    className: "newOrder",
    name: "Neue Bestellung",
    icon: "shopping-bag",
    iconColor: "var(--onSurfaceStrong)"
  },
  [notificationTypes.INFO]: {
    className: "info",
    name: "Information",
    icon: "info",
    iconColor: "var(--onSurfaceStrong)"
  },
  [notificationTypes.WARNING]: {
    className: "warning",
    name: "Warnung",
    icon: "alert-triangle",
    iconColor: "var(--onWarning)"
  },
  [notificationTypes.ERROR]: {
    className: "error",
    name: "Fehler",
    icon: "zap",
    iconColor: "var(--onError)"
  }
}