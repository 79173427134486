import { put, select, takeEvery, call } from "redux-saga/effects";
import { addGlobalMessageRequested } from "../actions/general";
import { addToOfferedProductsSucceeded, getCategoriesSucceeded, retrieveOfferedProductsSucceeded, retrieveProductsSucceeded, setOfferedProductMapping, removeFromOfferedProductsSucceeded } from "../actions/product";
import { authAPIRequest } from "../api/requests";
import { notificationTypes } from "../data/notificatonTypes";
import { genericHandleResult } from "./sagaHelper";

export function* watchAllProductActions() {
  yield takeEvery("GET_CATEGORIES_REQUESTED", beginGetCategories);
  yield takeEvery("RETRIEVE_PRODUCTS_REQUESTED", beginRetrieveProducts);
  yield takeEvery("RETRIEVE_OFFERED_PRODUCTS_REQUESTED", beginRetrieveOfferedProducts);
  yield takeEvery("ADD_TO_OFFERED_PRODUCTS_REQUESTED", beginAddToOfferedProducts);
  yield takeEvery("REMOVE_FROM_OFFERED_PRODUCTS_REQUESTED", beginRemoveFromOfferedProducts);
}

function* beginGetCategories() {
  try {
    // const token = yield select(state => state.authentication.token);
    // const request = yield call(() => authAPIRequest("/store/get-product-categories", { }, token));

    const request = {
      statusCode: 0, result: [
        { "id": 1, "name": "Saisonales" },
        { "id": 2, "name": "Brote" },
        { "id": 3, "name": "Brötchen" },
        { "id": 4, "name": "Belegtes" },
        { "id": 5, "name": "Laugengebäck" },
        { "id": 6, "name": "Süßes" },
        { "id": 7, "name": "Kuchen & Torten" },
        { "id": 8, "name": "Snacks & Salate" },
        { "id": 9, "name": "Sonstiges" },
        { "id": 10, "name": "Überraschungstüte" }
      ]
    };

    yield genericHandleResult(
      request.statusCode,
      getCategoriesSucceeded(request.result),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR }),
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginRetrieveProducts() {
  try {
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/store/get-all-products", { }, token));
    yield genericHandleResult(
      request.statusCode,
      retrieveProductsSucceeded(request.result),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR }),
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginRetrieveOfferedProducts() {
  try {
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/store/get-offered-products", { }, token));

    const opMapping = {};
    if (request.result) request.result.forEach(p => opMapping[p.id] = true);

    yield genericHandleResult(
      request.statusCode,
      retrieveOfferedProductsSucceeded(request.result),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR }),
      [setOfferedProductMapping(opMapping)]
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginAddToOfferedProducts(action) {
  try {
    const { productId, dailyAmountAvailable, handleResponse } = action; // dailyAmountAvailable is optional, can be undefined
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/store/add-to-offered-products", { 
      productId, 
      dailyLimit: dailyAmountAvailable ? { amountMax: dailyAmountAvailable } : undefined
    }, token));
    const offeredProductMapping = yield select(state => state.product.offeredProductMapping);
    handleResponse();

    yield genericHandleResult(
      request.statusCode,
      addToOfferedProductsSucceeded(productId, dailyAmountAvailable),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR }),
      [setOfferedProductMapping({ ...offeredProductMapping, [productId]: true })]
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginRemoveFromOfferedProducts(action) {
  try {
    const { productId, handleResponse } = action;
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/store/remove-from-offered-products", { productId }, token));

    const offeredProductMapping = yield select(state => state.product.offeredProductMapping);
    // const { [productId]: _, ...newOfferedProductMapping } = offeredProductMapping; // Not working on WKWebView on iOS
    const newOfferedProductMapping = Object.assign({}, offeredProductMapping);
    delete newOfferedProductMapping[productId];
    handleResponse();

    yield genericHandleResult(
      request.statusCode,
      removeFromOfferedProductsSucceeded(productId),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR }),
      [setOfferedProductMapping(newOfferedProductMapping)]
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}