const initialState = {
  store: null,
  openingHours: null,
  phoneNumber: null,
  specialHours: null,
  orderTimeSettings: null,
  availableStoreTags: null, // [{ id: 33, name: "Haus" }, { id: 5, name: "FastLane" }],
  activeStoreTags: null
};

export const store = (state = initialState, action) => {
  switch (action.type) {
    case "REMOVE_STORE_TAG_SUCCEEDED": {
      if (!state.activeStoreTags) return state;
      const newActiveStoreTags = state.activeStoreTags.filter(t => t.id !== action.id);
      return {
        ...state,
        activeStoreTags: newActiveStoreTags
      }
    }
    case "SET_STORE_TAG_SUCCEEDED": {
      if (!state.activeStoreTags) return state;
      return {
        ...state,
        activeStoreTags: [
          ...state.activeStoreTags,
          action.storeTag
        ]
      }
    }
    case "GET_STORE_TAGS_SUCCEEDED": {
      return {
        ...state,
        availableStoreTags: action.availableStoreTags,
        activeStoreTags: action.activeStoreTags
      }
    }
    case "UPDATE_ORDER_TIME_SETTINGS_SUCCEEDED": {
      return {
        ...state,
        orderTimeSettings: action.orderTimeSettings
      }
    }
    case "ADD_SPECIAL_HOURS_SUCCEEDED": {
      if (!state.specialHours) return state;
      return {
        ...state,
        specialHours: [
          ...state.specialHours,
          action.specialHoursData
        ]
      }
    }
    case "DELETE_SPECIAL_HOURS_SUCCEEDED": {
      if (!state.specialHours) return state;
      const newSpecialHours = state.specialHours.filter(s => s.id !== action.id);
      return {
        ...state,
        specialHours: newSpecialHours
      }
    }
    case "UPDATE_PHONE_NUMBER_SUCCEEDED": {
      return {
        ...state,
        phoneNumber: action.phoneNumber
      }
    }
    case "UPDATE_OPENING_HOURS_SUCCEEDED": {
      return {
        ...state,
        openingHours: action.openingHours
      }
    }
    case "GET_STORE_DETAILS_SUCCEEDED": {
      const { openingHours, store, specialDays, orderTimeSettings } = action.storeDetails;
      return {
        ...state,
        store,
        openingHours,
        phoneNumber: store.phone,
        specialHours: specialDays,
        orderTimeSettings
      }
    }
    default:
      return state;
  }
};
