import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDetailsRequested, updateOrderStateRequested } from "../../actions/order";
import { orderStateActionMapping, orderStateInfo, orderStates } from "../../data/orderStates";
import { convertToCurrency } from "../../utils/conversion";
import { getReadableDateTime } from "../../utils/datetime";
import { DefaultButton } from "../buttons/DefaultButton";
import { IconButton } from "../buttons/IconButton";
import { ActivityIndicator } from "../loading/ActivityIndicator";
import { VariableDisplay } from "../text/VariableDisplay";
import { OrderProductEntry } from "./OrderProductEntry";
import { OrderStateDisplay } from "./OrderStateDisplay";
import { OrderStateIndicator } from "./OrderStateIndicator";
import { surprisePackageCategoryId } from "../../data/categoryData";
import { setOpenModal } from "../../actions/general";
import { ModalType } from "../../helpers/modals";

export const OrderDetails = ({ selectedOrderId }) => {
  const order = useSelector(state => state.order.orders[selectedOrderId]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getOrderDetailsRequested(selectedOrderId));
  }, [selectedOrderId]); // eslint-disable-line react-hooks/exhaustive-deps

  if (order) {
    const { id, pickupCode, executionTime, orderStateHistory, user, products, reason } = order;
    const currentOrderState = orderStateHistory[orderStateHistory.length - 1];

    // Acquire missing order steps for default order-accepted process
    const defaultOrderStateProcess = [orderStates.SUBMITTED, orderStates.CONFIRMED, orderStates.PACKED, orderStates.COMPLETED];
    const matchingDefaultStepLength = defaultOrderStateProcess.filter((v, i) => orderStateHistory[i] === v).length;
    const missingDefaultSteps = (matchingDefaultStepLength > 1) || currentOrderState === orderStates.SUBMITTED ? Array.from(defaultOrderStateProcess).splice(matchingDefaultStepLength) : null;

    // Acquire missing order steps for default order-declined process
    const declineOrderStateProcess = [orderStates.SUBMITTED, orderStates.DECLINED];
    const matchingDeclineStepLength = declineOrderStateProcess.filter((v, i) => orderStateHistory[i] === v).length;
    const missingDeclineSteps = matchingDeclineStepLength > 1 ? Array.from(declineOrderStateProcess).splice(matchingDeclineStepLength) : null;

    const hasSurprisePackageProducts = products.find((p) => p.categoryId === surprisePackageCategoryId);
    const { firstName, lastName, phone } = user;
    const productList = products.map(p => <OrderProductEntry key={p.id} {...p} />)
    const total = products.reduce((acc, p) => acc + (p.amount * p.price), 0);
    return (
      <div className="order">
        {!orderStateInfo[currentOrderState].completed && <div className="openIndicator" />}
        {currentOrderState === orderStates.SUBMITTED && <div className="infoBox">
          <span className="warning bold">Diese Bestellung wurde nach der Frist für die garantierte Verfügbarkeit getätigt und muss daher manuell angenommen oder abgelehnt werden.</span>
        </div>}
        <div className="orderHeader">
          <div className="orderHeaderLeft">
            <span className="bold large">Bestellung #{id}</span>
            <OrderStateDisplay executionTime={executionTime} currentOrderState={currentOrderState} reason={reason} />

            <div className="topInfos">
              <VariableDisplay label="Art" value="Abholung" />
              <VariableDisplay label="Zeitpunkt" value={getReadableDateTime(executionTime)} />
              {hasSurprisePackageProducts && <span><span className="badge large">Überraschungstüte</span></span>}
            </div>
          </div>
          <div className="orderHeaderRight">
            {currentOrderState !== orderStates.SUBMITTED && <div className="largePickupCode">
              <span className="bold extraLarge">{pickupCode}</span>
            </div>}
          </div>
        </div>

        <div className="orderProducts">
          <span className="bold large">Bestellte Waren</span>
          <div className="productList">
            {productList}
          </div>
          <div className="orderProductSummary">
            <span>Zwischensumme</span>
            <span>{convertToCurrency(total)}</span>
          </div>
          <div className="orderProductSummary">
            <span className="bold big">Gesamtsumme</span>
            <span className="bold big">{convertToCurrency(total)}</span>
          </div>
        </div>

        <div className="orderBottom">
          <div className="orderContact">
            <VariableDisplay label="Kunde" value={!firstName && !lastName ? "(Gelöschter Nutzer)" : firstName + " " + lastName} />
            <VariableDisplay label="Telefonnummer" value={phone ? phone : "-"} />
          </div>
          <div className="orderState">
            {currentOrderState === orderStates.SUBMITTED && !loading && (
              <div className="unacceptedContainer">
                <span className="bold big">Möchten Sie diese Bestellung annehmen?</span>
                <div className="actions">
                  <DefaultButton text="Annehmen" onPress={() => dispatch(updateOrderStateRequested(id, orderStates.CONFIRMED, null, setLoading))} />
                  <DefaultButton text="Ablehnen" onPress={() => dispatch(setOpenModal(ModalType.RejectionReason, { id, products }))} isFlat={true} />
                </div>
              </div>
            )}
            {currentOrderState !== orderStates.SUBMITTED && (<div className="orderStateProgress">
              {orderStateHistory.map((s, i) => {
                // if (i === 0) return null;
                const { storeLabel, storeStepNr } = orderStateInfo[s];
                return <OrderStateIndicator key={"cos" + i} stepNr={storeStepNr} label={storeLabel} isCompleted={true} isFirst={i === 0} />
              })}
              {(missingDefaultSteps ? missingDefaultSteps : missingDeclineSteps).map((s, i) => {
                const { storeLabel, storeStepNr } = orderStateInfo[s];
                return <OrderStateIndicator key={"mos" + i} stepNr={storeStepNr} label={storeLabel} isCompleted={false} />
              })}
            </div>)}
            {!loading && [orderStates.SUBMITTED, orderStates.DECLINED, orderStates.COMPLETED].indexOf(currentOrderState) === -1 &&
              <div>
                <IconButton
                  icon="check"
                  text={orderStateActionMapping[currentOrderState].actionLabel}
                  onPress={() => dispatch(updateOrderStateRequested(id, orderStateActionMapping[currentOrderState].nextOrderState, null, setLoading))}
                />
              </div>}
            {loading && <ActivityIndicator />}
          </div>
        </div>
      </div>
    )
  } else {
    return (
      <ActivityIndicator />
    )
  }
}