import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/authentication";
import { SidemenuHeader } from "./SidemenuHeader";
import "./../../style/components/Sidemenu.scss";
import { SidemenuEntry } from "./SidemenuEntry";
import { disconnectSocket } from "../../socketio";

export const Sidemenu = ({ sidemenuCollapsed, setSidemenuCollapsed, isTablet }) => {
  const openOrderAmount = useSelector(state => state.order.openOrders ? state.order.openOrders.length : 0);
  const dispatch = useDispatch();

  const doLogout = () => {
    disconnectSocket();
    dispatch(logout());
  }

  return (
    <div className="sidemenu">
      <SidemenuHeader sidemenuCollapsed={sidemenuCollapsed} setSidemenuCollapsed={setSidemenuCollapsed} />
      <div className="entryListTop">
        <SidemenuEntry icon="shopping-bag" label="Bestellungen" activePathnames={["orders", "order"]} navRoute="/orders" isTablet={isTablet} setSidemenuCollapsed={setSidemenuCollapsed} notificationCount={openOrderAmount} />
        <SidemenuEntry icon="package" label="Sortiment" activePathnames={["products", "product"]} navRoute="/products" isTablet={isTablet} setSidemenuCollapsed={setSidemenuCollapsed} />
        <SidemenuEntry icon="users" label="Kunden" activePathnames={["customers", "customer"]} navRoute="/customers" isTablet={isTablet} setSidemenuCollapsed={setSidemenuCollapsed} />
      </div>
      <div className="entryListBottom">
        <SidemenuEntry icon="help-circle" label="Hilfe" activePathnames={["support"]} navRoute="/support" isTablet={isTablet} setSidemenuCollapsed={setSidemenuCollapsed} />
        <SidemenuEntry icon="home" label="Filialeinstellungen" activePathnames={["settings"]} navRoute="/settings" isTablet={isTablet} setSidemenuCollapsed={setSidemenuCollapsed} />
        <SidemenuEntry icon="log-out" label="Abmelden" onPress={doLogout} />
      </div>
    </div>
  )
}