import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Feather as Icon } from 'react-web-vector-icons';

export const SidemenuEntry = ({ icon, label, onPress, activePathnames, navRoute, isTablet, setSidemenuCollapsed, notificationCount }) => {
  const history = useHistory();
  const location = useLocation();
  const rootPath = location.pathname.split('/')[1];

  const active = (activePathnames && activePathnames.indexOf(rootPath) !== -1) ? true : false;
  const handlePress = () => {
    if (onPress) onPress();
    if (navRoute) history.push(navRoute)
    if (isTablet && setSidemenuCollapsed) setTimeout(() => setSidemenuCollapsed(true), 160);
  }

  return (
    <div className={`sidemenuEntry${active ? ' active' : ''}`}>
      <div className="activeHighlight" onClick={() => handlePress()}>
        {notificationCount && notificationCount > 0 ? <div className="notificationAmount"><span>{notificationCount}</span></div> : null}
        <Icon name={icon} color='var(--onPrimary)' size={20} />
        <span>{label}</span>
      </div>
    </div>
  )
}