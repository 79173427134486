import React, { useState } from "react";
import { Feather as Icon } from 'react-web-vector-icons';
import { useDispatch } from "react-redux";
import { DefaultButton } from "../buttons/DefaultButton";
import { setStoreTagRequested, removeStoreTagRequested } from "../../actions/store";

export const StoreTagList = ({ availableStoreTags, activeStoreTags }) => {
  if (!availableStoreTags || !activeStoreTags) return null;

  console.log({ availableStoreTags, activeStoreTags })

  const displayList = activeStoreTags.map(t => <StoreTagEntry key={t.id} {...t} isActive={true} />);
  const stillAvailableList = availableStoreTags.filter(t => !activeStoreTags.find(at => at.id === t.id));
  displayList.push(...stillAvailableList.map(t => <StoreTagEntry key={t.id} {...t} isActive={false} />));

  return (
    <div className="storeTagList">
      {displayList}
      {availableStoreTags.length === 0 && activeStoreTags.length === 0 && <span className="empty">Keine verfügbaren Besonderheiten gefunden.</span>}
    </div>
  )
}

const StoreTagEntry = ({ id, name, isActive }) => {
  const [deletePressed, setDeletePressed] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const deleteStoreTag = () => {
    setLoading(true);
    dispatch(removeStoreTagRequested(id, () => {
      setLoading(false);
      setDeletePressed(false);
    }));
  }

  const setStoreTag = () => {
    setLoading(true);
    dispatch(setStoreTagRequested({ id, name }, () => {
      setLoading(false);
    }));
  }

  return (
    <div className="storeTagEntry">
      <div className="activeIndicator">
        <Icon name={isActive ? "check" : "unlock"} color={isActive ? 'var(--onSurfaceStrong)' : 'var(--onSurfaceLight)'} size={20} />
      </div>
      <span className="tagName">{name}</span>
      {!deletePressed && isActive && <div className="deleteOption" onClick={() => setDeletePressed(true)}>
        <Icon name="x" color='var(--onSurfaceStrong)' size={20} />
      </div>}
      {deletePressed && <DefaultButton text="Löschen" loading={loading} isDelete={true} isFlat={true} onPress={() => deleteStoreTag()} />}
      {!isActive && <DefaultButton text="Hinzufügen" loading={loading} isFlat={true} onPress={() => setStoreTag()} />}
    </div>
  )
}