import React from "react";
import { useSelector } from "react-redux";
import { Feather as Icon } from 'react-web-vector-icons';

export const SidemenuHeader = ({ sidemenuCollapsed, setSidemenuCollapsed }) => {
  const companyName = useSelector(state => state.company.name);
  const store = useSelector(state => state.store.store);
  return (
    <div className="sidemenuHeader">
      <div className="textWrapper">
        <span className="title">{companyName}</span>
        <span className="subtitle">{store ? store.name : ""}</span>
      </div>
      <div className="clickable" onClick={() => setSidemenuCollapsed(!sidemenuCollapsed)}><Icon name='menu' color='var(--onPrimary)' size={24} /></div>
    </div>
  )
}