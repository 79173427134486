import { put, takeEvery, call, select } from "redux-saga/effects";
import { addSpecialHoursSucceeded, deleteSpecialHoursSucceeded, getStoreDetailsSucceeded, getStoreTagsSucceeded, removeStoreTagSucceeded, setStoreTagSucceeded, updateOpeningHoursSucceeded, updateOrderTimeSettingsSucceeded, updatePhoneNumberSucceeded } from "../actions/store";
import { addGlobalMessageRequested } from "../actions/general";
import { genericHandleResult } from "./sagaHelper";
import { statusCodes } from "../api/config";
import { authAPIRequest } from "../api/requests";
import { notificationTypes } from "../data/notificatonTypes";

export function* watchAllStoreActions() {
  yield takeEvery("GET_STORE_DETAILS_REQUESTED", beginGetStoreDetails);
  yield takeEvery("UPDATE_OPENING_HOURS_REQUESTED", beginUpdateOpeningHours);
  yield takeEvery("UPDATE_PHONE_NUMBER_REQUESTED", beginUpdatePhoneNumber);
  yield takeEvery("DELETE_SPECIAL_HOURS_REQUESTED", beginDeleteSpecialHour);
  yield takeEvery("ADD_SPECIAL_HOURS_REQUESTED", beginAddSpecialHour);
  yield takeEvery("UPDATE_ORDER_TIME_SETTINGS_REQUESTED", beginUpdateOrderTimeSettings);
  yield takeEvery("GET_STORE_TAGS_REQUESTED", beginGetStoreTags);
  yield takeEvery("SET_STORE_TAG_REQUESTED", beginSetStoreTag);
  yield takeEvery("REMOVE_STORE_TAG_REQUESTED", beginRemoveStoreTag);
}

function* beginGetStoreDetails() {
  try {
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/store/get-store-details", { }, token));
    // const request = {
    //   statusCode: 0, result: {
    //     store: {
    //       name: "Härdtner Neckarsulm",
    //       phone: "+49123456789",
    //       imageUrl: "https://assets.brotbox.app/3ad2f867-f462-4062-9a90-bc5cd5102509/storeimages/88392d3a-e8c3-4562-a558-0a5d191c4654.jpeg"
    //     },
    //     openingHours: [
    //       { dayOfWeek: 0, openTime: "08:00", closeTime: "16:00", closed: 0 },
    //       { dayOfWeek: 1, openTime: "08:00", closeTime: "16:00", closed: 0 },
    //       { dayOfWeek: 2, openTime: "08:00", closeTime: "16:00", closed: 0 },
    //       { dayOfWeek: 3, openTime: "08:00", closeTime: "16:00", closed: 0 },
    //       { dayOfWeek: 4, openTime: "08:00", closeTime: "12:00", closed: 0 },
    //       { dayOfWeek: 5, openTime: "12:00", closeTime: "16:00", closed: 1 },
    //       { dayOfWeek: 6, openTime: "08:00", closeTime: "16:00", closed: 1 }
    //     ],
    //     // phone: "+49123456789",
    //     specialHours: [
    //       {
    //         id: 2,
    //         date: "2021-03-17",
    //         openTime: "07:00",
    //         closeTime: "12:00",
    //         closed: 0
    //       }
    //     ],
    //     orderTimeSettings: {
    //       minMinsBeforeOrder: 30,
    //       orderAcceptanceMode: 1,
    //       untilTime: "18:00",
    //       daysBefore: 1
    //     }
    //   }
    // }
    // yield delay(400);

    yield genericHandleResult(
      request.statusCode,
      getStoreDetailsSucceeded(request.result),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginUpdateOpeningHours(action) {
  try {
    const { openingHours, handleResponse } = action;
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/store/update-opening-hours", { openingHours }, token));
    // const request = { statusCode: 0 }
    // const request = { statusCode: 3, errorMsg: "Geht ned" }
    // yield delay(400);
    handleResponse(request.statusCode === statusCodes.SUCCESS, request.errorMsg);

    yield genericHandleResult(
      request.statusCode,
      updateOpeningHoursSucceeded(openingHours),
      null // addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginUpdatePhoneNumber(action) {
  try {
    const { phoneNumber, handleResponse } = action;
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/store/update-phone", { phone: phoneNumber }, token));
    // const request = { statusCode: 0 }
    // const request = { statusCode: 3, errorMsg: "Geht ned" }
    // yield delay(400);
    handleResponse(request.statusCode === statusCodes.SUCCESS, request.errorMsg);

    yield genericHandleResult(
      request.statusCode,
      updatePhoneNumberSucceeded(phoneNumber),
      null // addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginDeleteSpecialHour(action) {
  try {
    const { id, handleResponse } = action;
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/store/remove-special-day", { specialDayId: id }, token));
    // const request = { statusCode: 0 }
    // yield delay(400);
    handleResponse();

    yield genericHandleResult(
      request.statusCode,
      deleteSpecialHoursSucceeded(id),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginAddSpecialHour(action) {
  try {
    const { specialHoursData, handleResponse } = action;
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/store/add-special-day", { ...specialHoursData }, token));
    // const request = { statusCode: 0, result: {
    //   ...specialHoursData,
    //   id: 33,
    //   date: "2021-03-22"
    // }}
    // yield delay(400);
    handleResponse(request.statusCode === statusCodes.SUCCESS, request.errorMsg);

    yield genericHandleResult(
      request.statusCode,
      request.result ? addSpecialHoursSucceeded({ id: request.result.specialDayId, ...specialHoursData }) : null,
      null // addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginUpdateOrderTimeSettings(action) {
  try {
    const { orderTimeSettings, handleResponse } = action;
    const token = yield select(state => state.authentication.token);
    const { orderAcceptanceMode, minMinsBeforeOrder, daysBefore, untilTime } = orderTimeSettings;
    const request = yield call(() => authAPIRequest("/store/update-order-time-settings", { orderAcceptanceMode, minMinsBeforeOrder, daysBefore, untilTime }, token));
    // const request = { statusCode: 0 }
    // const request = { statusCode: 3, errorMsg: "Geht ned" }
    // yield delay(400);
    handleResponse(request.statusCode === statusCodes.SUCCESS, request.errorMsg);

    yield genericHandleResult(
      request.statusCode,
      updateOrderTimeSettingsSucceeded(orderTimeSettings),
      null // addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginGetStoreTags() {
  try {
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/store/get-store-tags", { }, token));

    yield genericHandleResult(
      request.statusCode,
      request.result ? getStoreTagsSucceeded(request.result.availableStoreTags, request.result.activeStoreTags) : null,
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginSetStoreTag(action) {
  try {
    const { storeTag, handleResponse } = action;
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/store/set-store-tag", { tagId: storeTag.id }, token));
    handleResponse();

    yield genericHandleResult(
      request.statusCode,
      setStoreTagSucceeded(storeTag),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginRemoveStoreTag(action) {
  try {
    const { id, handleResponse } = action;
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/store/remove-store-tag", { tagId: id }, token));
    handleResponse();

    yield genericHandleResult(
      request.statusCode,
      removeStoreTagSucceeded(id),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}