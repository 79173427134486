import React, { useState, useEffect } from "react";
import { ActivityIndicator } from "../components/loading/ActivityIndicator";
import { Feather as Icon } from 'react-web-vector-icons';
import { useDispatch, useSelector } from "react-redux";
import { ProductEntry } from "../components/product/ProductEntry";
import { retrieveOfferedProductsRequested, retrieveProductsRequested } from "../actions/product";
import { ProductSectionList } from "../components/product/ProductSectionList";
import { Route } from "react-router-dom";
import "./../style/routes/Product.scss";
import { ProductDetails } from "../components/product/ProductDetails";

export const Products = () => {
  const products = useSelector(state => state.product.products);
  const [searchString, setSearchString] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(retrieveProductsRequested());
    dispatch(retrieveOfferedProductsRequested());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const filteredProducts = (searchString === "") ? products : products && products.filter(p => p.name.toLowerCase().includes(searchString.toLowerCase()));
  const productList = filteredProducts && filteredProducts.map(p => <ProductEntry key={p.id} {...p} />);

  return (
    <div className="appContent">
      <div className="pageHeader">
        <div className="pageHeaderLeft">
          <span className="pageTitle">Sortiment</span>
          <span className="pageDescription">Ermöglicht die Verwaltung des Produktsortiments</span>
        </div>
      </div>
      <div className="pageContent">
        <div className="card maxHeight leftList">
          <div className="searchHeader">
            <Icon name="search" color='var(--onSurfaceStrong)' size={20} />
            <input type="text" placeholder="Nach Bezeichnung filtern" value={searchString} onChange={e => setSearchString(e.target.value)} />
          </div>
          <div className="listContent hasSearchHeader">
            {products !== null ? productList : <ActivityIndicator />}
            {(filteredProducts && filteredProducts.length === 0) && <span className="emptyText">Keine Produkte gefunden</span>}
          </div>
        </div>
        <div className="card maxHeight">
          <Route exact path="/products" component={ProductSectionList} />
          <Route exact path="/product/:selectedProductId" component={ProductDetails} />
        </div>
      </div>
    </div>
  )
}