import { put, takeEvery, call } from "redux-saga/effects";
import { addGlobalMessageRequested } from "../actions/general";
import { statusCodes } from "../api/config";
import { basicAPIRequest } from "../api/requests";
import { notificationTypes } from "../data/notificatonTypes";

export function* watchAllCompanyActions() {
  yield takeEvery("COMPANY_LOOKUP_REQUESTED", beginCompanyLookup);
}

function* beginCompanyLookup(action) {
  try {
    const { urlIdentifier, handleResponse } = action;
    const request = yield call(() => basicAPIRequest("/company/company-lookup", { urlCode: urlIdentifier }));
    handleResponse({ exists: request.statusCode === statusCodes.SUCCESS, result: request.result, errorMsg: request.errorMsg });
  } catch (e) {
    console.log(e.message);
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}