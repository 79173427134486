const initialState = {
  categories: null,
  products: null,
  offeredProducts: null,
  offeredProductMapping: {}
};

export const product = (state = initialState, action) => {
  console.log(action)
  switch (action.type) {
    case "REMOVE_FROM_OFFERED_PRODUCTS_SUCCEEDED": {
      const newOfferedProducts = state.offeredProducts.filter(p => p.id !== action.productId);
      return {
        ...state,
        offeredProducts: newOfferedProducts
      }
    }
    case "ADD_TO_OFFERED_PRODUCTS_SUCCEEDED": {
      if (!state.offeredProducts || !state.products) return state;

      const { productId, dailyAmountAvailable } = action;
      const pr = state.products.find(p => p.id === productId);

      return {
        ...state,
        offeredProducts: [
          ...state.offeredProducts,
          dailyAmountAvailable ? {...pr, dailyLimit: { amountMax: dailyAmountAvailable, amountLeft: dailyAmountAvailable }} : pr
        ]
      }
    }
    case "SET_OFFERED_PRODUCT_MAPPING": {
      return {
        ...state,
        offeredProductMapping: action.productMapping
      }
    }
    case "RETRIEVE_OFFERED_PRODUCTS_SUCCEEDED": {
      console.log({x: action.productList})
      return {
        ...state,
        offeredProducts: action.productList
      }
    }
    case "RETRIEVE_PRODUCTS_SUCCEEDED": {
      console.log({y: action.productList})
      return {
        ...state,
        products: action.productList
      }
    }
    case "GET_CATEGORIES_SUCCEEDED": {
      return {
        ...state,
        categories: action.categoryList
      }
    }
    default:
      return state;
  }
};
