import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToOfferedProductsRequested, removeFromOfferedProductsRequested } from "../../actions/product";
import { taxOptions } from "../../data/productData";
import { convertToCurrency } from "../../utils/conversion";
import { DefaultButton } from "../buttons/DefaultButton";
import { DetailActionButton } from "../buttons/DetailActionButton";
import { ActivityIndicator } from "../loading/ActivityIndicator";
import { VariableDisplay } from "../text/VariableDisplay";
import { NutritionTable } from "./NutritionTable";
import { DateTime } from 'luxon';
import { surprisePackageCategoryId } from "../../data/categoryData";
import { DefaultInput } from '../inputs/DefaultInput';

export const ProductDetails = ({ match, history }) => {
  const selectedProductId = parseInt(match.params.selectedProductId);
  const product = useSelector(state => state.product.products ? state.product.products.filter(p => p.id === selectedProductId)[0] : null);
  const categories = useSelector(state => state.product.categories);
  const isInOfferedProducts = useSelector(state => state.product.offeredProductMapping[selectedProductId]);
  const offeredProductData = useSelector(state => state.product.offeredProducts ? state.product.offeredProducts.filter(p => p.id === selectedProductId)[0] : null);
  const [loading, setLoading] = useState(false);
  const [insertAmountMode, setInsertAmountMode] = useState(false);
  const [dailyAmountAvailable, setDailyAmountAvailable] = useState(3);
  const dispatch = useDispatch();

  const productCategory = product && categories && categories.find(c => c.id === product.categoryId);
  const dataDayNames = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
  const viewDayNames = ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"];
  const availableDayString = product && product.availability && dataDayNames.reduce((a, d, i) => {
    if (product.availability[d]) {
      if (a !== "") return a + ", " + viewDayNames[i];
      return a + viewDayNames[i];
    } else {
      return a;
    }
  }, "");

  useEffect(() => {
    setInsertAmountMode(productCategory && productCategory.id === surprisePackageCategoryId);
  }, [product, productCategory]);

  const specialAvailableDayString = product && product.specialAvailability && product.specialAvailability.reduce((a, d) => {
    const prefix = a === "" ? "" : ", ";
    if (d.startDate === d.endDate) {
      const sDate = DateTime.fromFormat(d.startDate, "yyyy-LL-dd").toFormat("dd.LL.yyyy");
      return a + prefix + sDate;
    } else {
      const sDate = DateTime.fromFormat(d.startDate, "yyyy-LL-dd").toFormat("dd.LL.yyyy");
      const eDate = DateTime.fromFormat(d.endDate, "yyyy-LL-dd").toFormat("dd.LL.yyyy");
      return a + prefix + `${sDate} - ${eDate}`;
    }
  }, "");

  const addToOfferedProducts = (dailyAmountAvailable) => {
    setLoading(true);
    // dailyAmountAvailable is optional, can be undefined
    dispatch(addToOfferedProductsRequested(selectedProductId, parseInt(dailyAmountAvailable), () => {
      setLoading(false);
    }));
  }

  const removeFromOfferedProducts = () => {
    setLoading(true);
    dispatch(removeFromOfferedProductsRequested(selectedProductId, () => {
      setLoading(false);
    }));
  }

  return product ? (
    <React.Fragment>
      <div className="detailHeader">
        <div className="detailHeaderTitle">
          <span className="big">Produktdetails</span>
        </div>
        <div className="detailHeaderActions">
          <DetailActionButton icon="x" onPress={() => history.push("/products")} />
        </div>
      </div>
      <div className="detailContent">
        <div className="offeredProductToggleContainer">
          {loading && <ActivityIndicator />}
          {isInOfferedProducts && !loading && (
            <React.Fragment>
              <span>Dieses Produkt wird derzeit im Sortiment dieser Filiale angeboten.</span>
              <DefaultButton text="Aus Sortiment entfernen" isFlat={true} isDelete={true} onPress={() => removeFromOfferedProducts()} />
            </React.Fragment>
          )}
          {!isInOfferedProducts && !loading && (
            <React.Fragment>
              <span>Dieses Produkt wird derzeit nicht im Sortiment dieser Filiale angeboten.</span>
              {!insertAmountMode && <DefaultButton text="Zum Sortiment hinzufügen" onPress={() => addToOfferedProducts()} />}
            </React.Fragment>
          )}
        </div>
        {!isInOfferedProducts && !loading && insertAmountMode && (
          <div className="offeredProductToggleContainer">
            <DefaultInput name="Anzahl verfügbar pro Tag:" type="number" value={dailyAmountAvailable} onChange={(e) => setDailyAmountAvailable(e.target.value)} />
            <span></span>
            <DefaultButton text="Zum Sortiment hinzufügen" onPress={() => addToOfferedProducts(dailyAmountAvailable)} />
          </div>
        )}
        <div className="product">
          <div className="productInfoContainer">
            <VariableDisplay label="Name" value={product.name} marginBottom={true} />
            <VariableDisplay label="Gewicht/Anzahl" value={product.measure} marginBottom={true} />
            <VariableDisplay label="Preis" value={convertToCurrency(parseFloat(product.price))} marginBottom={true} />
            <VariableDisplay label="Steuersatz" value={taxOptions.find(t => t.id === product.taxId).name} marginBottom={true} />
            <VariableDisplay label="Kategorie" value={productCategory ? productCategory.name : "-"} marginBottom={true} />
            <VariableDisplay label="Beschreibung" value={product.description} marginBottom={true} boldValueText={false} />
            <VariableDisplay label="Inhaltsstoffe" value={product.ingredients} marginBottom={true} boldValueText={false} />
            <VariableDisplay label="Allergiehinweise" value={product.allergyAdvice} marginBottom={true} boldValueText={false} />
            <VariableDisplay label="Verfügbarkeit" value={product.specialAvailability && product.specialAvailability.length > 0 ? specialAvailableDayString : availableDayString} marginBottom={true} boldValueText={false} />
            {isInOfferedProducts && offeredProductData && offeredProductData.dailyLimit && <VariableDisplay label="Täglicher Bestand" value={`${offeredProductData.dailyLimit.amountLeft} von ${offeredProductData.dailyLimit.amountMax}`} marginBottom={true} boldValueText={false} />}
            {product.nutritionFacts && !!product.nutritionFacts.enabled && <NutritionTable editMode={false} nutritionFacts={product.nutritionFacts} />}
          </div>
          <div className="productImageContainer">
            {product.imageUrl ? <img src={product.imageUrl} alt="Produktbild" /> : <div className="imgEmpty" />}
          </div>
        </div>
      </div>
    </React.Fragment>
  ) : <ActivityIndicator />
}