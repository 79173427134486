import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import { storeLoginRequested } from "../actions/authentication";
import { companyLookupRequested, setCompanyInfos } from "../actions/company";
import { DefaultButton } from "../components/buttons/DefaultButton";
import { DefaultInput } from "../components/inputs/DefaultInput";
import { ActivityIndicator } from "../components/loading/ActivityIndicator";
import { shadeColor } from "../utils/color";
import { setDynamicManifest } from "./../data/dynamicManifest";
import "./../style/routes/Login.scss";

export const Login = ({ match }) => {
  const loggedIn = useSelector(state => state.authentication.loggedIn);
  const [lookupResult, setLookupResult] = useState(null);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorShown, setErrorShown] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loggedIn) dispatch(companyLookupRequested(match.params.urlIdentifier, setLookupResult));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (lookupResult && lookupResult.result) {
      const { id, name, primaryColor, onPrimaryColor } = lookupResult.result;
      document.documentElement.style.setProperty("--primary", primaryColor);
      document.documentElement.style.setProperty("--primaryLight", shadeColor(primaryColor, 20));
      document.documentElement.style.setProperty("--primaryVeryLight", shadeColor(primaryColor, 40));
      document.documentElement.style.setProperty("--onPrimary", onPrimaryColor);
      dispatch(setCompanyInfos(id, name, { primary: primaryColor, onPrimary: onPrimaryColor }, match.params.urlIdentifier));
      setDynamicManifest(match.params.urlIdentifier);
    }
  }, [lookupResult]); // eslint-disable-line react-hooks/exhaustive-deps

  const doLogin = () => {
    setIsLoading(true);
    dispatch(storeLoginRequested(username, password, lookupResult.result.id, handleResult));
  }

  const handleResult = (success) => {
    if (!success) {
      setPassword("");
      setErrorShown(true);
      setIsLoading(false);
    } else {
      setErrorShown(false);
    }
  }

  if (loggedIn) return <Redirect to={{ pathname: "/orders" }} />
  return lookupResult ? (lookupResult.exists ? (
    <div className="loginContainer">
      <div className="wrapper form">
        <h1 className="bold">{lookupResult.result.name}</h1>
        <span>Bitte melde dich an um fortzufahren.</span>

        <div className="inputWrapper">
          <DefaultInput
            name="Nutzername"
            placeholder="Nutzernamen eingeben"
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            onKeyUp={e => {
              if (e.keyCode === 13) doLogin();
            }}
          />
          <DefaultInput
            name="Passwort"
            placeholder="Passwort eingeben"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onKeyUp={e => {
              if (e.keyCode === 13) doLogin();
            }}
          />
        </div>

        {errorShown && <span className="errorMsg">Die angegebenen Anmeldedaten sind ungültig. Bitte versuche es erneut.</span>}

        <DefaultButton text="Login" loading={isLoading} onPress={() => doLogin()} />
      </div>
    </div>
  ) : (
    <div className="loginContainer">
      <div className="wrapper">
        <p>Ungültige Anfrage.</p>
      </div>
    </div>
  )) : (
    <div className="loginContainer">
      <div className="wrapper loadingWrapper">
        <ActivityIndicator />
      </div>
    </div>
  )
}