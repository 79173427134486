import React, { createRef, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { convertToCurrency } from "../../utils/conversion";
import { ActivityIndicator } from "../loading/ActivityIndicator";

export const ProductSectionList = ({ history }) => {
  const [selectedCategory, setSelectedCategory] = useState(1);
  const offeredProducts = useSelector(state => state.product.offeredProducts);
  const categoryList = useSelector(state => state.product.categories);
  const categoryRefs = useRef([]);

  useEffect(() => {
    if (categoryList) {
      categoryRefs.current = categoryList.map(() => createRef());
    }
  }, [categoryList]);

  useEffect(() => {
    if (!categoryList) return;
    const index = categoryList.findIndex(c => c.id === selectedCategory);
    if (index !== -1) {
      if (categoryRefs.current[index].current) categoryRefs.current[index].current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, [selectedCategory, categoryList]);

  const categoryCompList = categoryList && categoryRefs.current && categoryList.map((c, i) => {
    return (
      <div key={c.id} ref={categoryRefs.current[i]} className="productSectionCategoryContainer">
        <span className="large medium">{c.name}</span>
        <div className="productSectionListContainer">
          {offeredProducts && offeredProducts.filter(p => p.categoryId === c.id).map(p => <OfferedProductItem key={p.id} {...p} history={history} />)}
          {offeredProducts && offeredProducts.filter(p => p.categoryId === c.id).length === 0 && <span className="emptyText">Keine Produkte</span>}
        </div>
      </div>
    )
  });

  return categoryList ? (
    <React.Fragment>
      <div className="detailHeader productSectionHeader">
        {categoryList.map(c => <ProductSectionHeaderItem
          key={c.id}
          id={c.id}
          name={c.name}
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />)}
      </div>
      {/* <div className="detailContent" onScroll={e => handleScroll(e)}> */}
      <div className="detailContent">
        <div className="productSectionContent">
          {categoryCompList}
        </div>
      </div>
    </React.Fragment >
  ) : <ActivityIndicator />
}

const ProductSectionHeaderItem = ({ id, name, selectedCategory, setSelectedCategory }) => {
  return (
    <div className="productSectionHeaderItem" onClick={() => setSelectedCategory(id)}>
      {id === selectedCategory && <div className="selectedIndicator" />}
      <span>{name}</span>
    </div>
  )
}

const OfferedProductItem = ({ id, name, imageUrl, measure, price, history }) => {
  return (
    <div className="offeredProductItem" onClick={() => history.push("/product/" + id)}>
      <div className="offeredProductImage">
        <img src={imageUrl} alt="Produktbild" />
      </div>
      <div className="offeredProductInfos">
        <span className="medium">{name}</span>
        <span>{`${measure} · ${convertToCurrency(price)}`}</span>
      </div>
    </div>
  )
}