export const retrieveOpenOrdersRequested = () => ({
  type: "RETRIEVE_OPEN_ORDERS_REQUESTED"
});

export const retrieveOpenOrdersSucceeded = (orders) => ({
  type: "RETRIEVE_OPEN_ORDERS_SUCCEEDED",
  orders
});

export const retrievePackedOrdersRequested = () => ({
  type: "RETRIEVE_PACKED_ORDERS_REQUESTED"
});

export const retrievePackedOrdersSucceeded = (orders) => ({
  type: "RETRIEVE_PACKED_ORDERS_SUCCEEDED",
  orders
});

export const retrieveDoneOrdersRequested = (lastId, handleResponse) => ({
  type: "RETRIEVE_DONE_ORDERS_REQUESTED",
  lastId,
  handleResponse
});

export const retrieveDoneOrdersSucceeded = (orders) => ({
  type: "RETRIEVE_DONE_ORDERS_SUCCEEDED",
  orders
});

export const getOrderDetailsRequested = (orderId) => ({
  type: "GET_ORDER_DETAILS_REQUESTED",
  orderId
});

export const getOrderDetailsSucceeded = (orderId, orderDetails) => ({
  type: "GET_ORDER_DETAILS_SUCCEEDED",
  orderId,
  orderDetails
});

export const updateOrderStateRequested = (orderId, newOrderState, reason, setLoading) => ({
  type: "UPDATE_ORDER_STATE_REQUESTED",
  orderId, newOrderState, reason, setLoading
});

export const updateOrderStateSucceeded = (orderId, nextOrderState, reason) => ({
  type: "UPDATE_ORDER_STATE_SUCCEEDED",
  orderId,
  nextOrderState,
  reason
});

export const insertNewOrder = (orderData) => ({
  type: "INSERT_NEW_ORDER",
  orderData
});