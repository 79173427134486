import React, { useState } from "react";
import { DefaultButton } from "../buttons/DefaultButton";
import { CheckBox } from "../inputs/CheckBox";
import { DefaultInput } from "../inputs/DefaultInput";
import { DateTime, Duration } from 'luxon';
import { useDispatch } from "react-redux";
import { addSpecialHoursRequested } from "../../actions/store";

export const AddSpecialHours = () => {
  const [dateValue, setDateValue] = useState("");
  const [closedValue, setClosedValue] = useState(false);
  const [openValue, setOpenValue] = useState("");
  const [closeValue, setCloseValue] = useState("");
  const [reasonValue, setReasonValue] = useState("");
  const [errorMsg, setErrorMsg] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const addSpecialHours = () => {
    setErrorMsg(null);
    const dateObject = DateTime.fromFormat(dateValue, "dd.LL.yyyy");
    if (!dateObject.isValid) {
      setErrorMsg("Das angegebene Datum ist ungültig.");
      return;
    }
    if (!closedValue) {
      const openObject = Duration.fromISOTime(openValue);
      if (!openObject.isValid) {
        setErrorMsg("Die angegebene Öffnungszeit ist ungültig.");
        return;
      }
      const closeObject = Duration.fromISOTime(closeValue);
      if (!closeObject.isValid) {
        setErrorMsg("Die angegebene Schließzeit ist ungültig.");
        return;
      }
      if (openObject.toMillis() > closeObject.toMillis() || openObject.toMillis() === closeObject.toMillis()) {
        setErrorMsg("Die angegebenen Öffnungszeiten sind ungültig.");
        return;
      }
    }

    setLoading(true);
    const transferData = {
      date: dateObject.toISODate(), 
      closed: closedValue, 
      openTime: closedValue ? "00:00" : openValue, 
      closeTime: closedValue ? "00:00" : closeValue,
      reason: reasonValue ? reasonValue : null
    };
    dispatch(addSpecialHoursRequested(transferData, (success, errorMsg) => {
      setLoading(false);
      if (success) {
        setDateValue("");
        setClosedValue(false);
        setOpenValue("");
        setCloseValue("");
        setReasonValue("");
      } else {
        setErrorMsg(errorMsg);
      }
    }));
  }

  return (
    <React.Fragment>
      <div className="addSpecialHours">
        <div className="topInputs">
          <DefaultInput className="dateInputValue" name="Datum" type="text" placeholder="17.03.2021" value={dateValue} onChange={(e) => setDateValue(e.target.value)} />
          <div className="spacer" />
          <div className="openIndicator">
            <CheckBox value={closedValue} onChange={(v) => setClosedValue(v)} />
            <span onClick={() => setClosedValue(!closedValue)}>{closedValue ? "Geschlossen" : "Geöffnet"}</span>
          </div>
          <DefaultInput className="timeInputValue" disabled={closedValue} name="von" type="text" placeholder="07:00" value={openValue} onChange={(e) => setOpenValue(e.target.value)} />
          <DefaultInput className="timeInputValue" disabled={closedValue} name="bis" type="text" placeholder="17:00" value={closeValue} onChange={(e) => setCloseValue(e.target.value)} />
        </div>
        <div>
          <DefaultInput className="" name="Grund (optional)" type="text" placeholder="z.B. Feiertag" value={reasonValue} onChange={(e) => setReasonValue(e.target.value)} />
        </div>
      </div>
      {errorMsg ? <span className="error">{errorMsg}</span> : null}
      <DefaultButton text="Sonderfall anlegen" loading={loading} onPress={() => addSpecialHours()} />
    </React.Fragment>
  )
}