export const retrieveCustomerListRequested = (filterString, lastId, append, handleResponse) => ({
  type: "RETRIEVE_CUSTOMER_LIST_REQUESTED",
  filterString, lastId, append, handleResponse
});

export const retrieveCustomerListSucceeded = (customerList, append) => ({
  type: "RETRIEVE_CUSTOMER_LIST_SUCCEEDED",
  customerList, append
});

export const selectCustomer = (customerId) => ({
  type: "SELECT_CUSTOMER",
  customerId
});

export const getCustomerOrderListRequested = (customerId) => ({
  type: "GET_CUSTOMER_ORDER_LIST_REQUESTED",
  customerId
});

export const getCustomerOrderListSucceeded = (customerId, orderList) => ({
  type: "GET_CUSTOMER_ORDER_LIST_SUCCEEDED",
  customerId, orderList
});