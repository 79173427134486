import { put, takeLatest, select, call, delay, takeEvery } from "redux-saga/effects";
import { getCustomerOrderListSucceeded, retrieveCustomerListSucceeded } from "../actions/customer";
import { addGlobalMessageRequested } from "../actions/general";
import { notificationTypes } from "../data/notificatonTypes";
import { genericHandleResult } from "./sagaHelper";
import { authAPIRequest } from "../api/requests";

export function* watchAllCustomerActions() {
  yield takeLatest("RETRIEVE_CUSTOMER_LIST_REQUESTED", beginRetrieveCustomerList);
  yield takeEvery("GET_CUSTOMER_ORDER_LIST_REQUESTED", beginGetCustomerOrders);
}

function* beginRetrieveCustomerList(action) {
  try {
    const { filterString, lastId, append, handleResponse } = action;
    
    // Delay to wait for following keystrokes
    if (filterString) yield delay(150);

    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/store/get-customers", { filterString, lastId }, token));
    if (request.result && request.result.length > 0) handleResponse(request.result.length, request.result[request.result.length - 1].id);
    else handleResponse(0, null);

    yield genericHandleResult(
      request.statusCode,
      retrieveCustomerListSucceeded(request.result, append),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginGetCustomerOrders(action) {
  try {
    const { customerId } = action;
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/store/get-customer-details", { userId: customerId }, token));

    yield genericHandleResult(
      request.statusCode,
      getCustomerOrderListSucceeded(customerId, request.result), // retrieveCustomerListSucceeded(request.result, append),
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}