import { put, takeEvery, call, select, delay } from 'redux-saga/effects';
import { addGlobalMessage, addGlobalMessageRequested, removeGlobalMessage, setMaintenanceMode } from '../actions/general';
import { statusCodes } from '../api/config';
import { authAPIRequest, basicAPIRequest } from '../api/requests';
import { notificationTypes } from '../data/notificatonTypes';
import { genericHandleResult } from './sagaHelper';

export function* watchAllGeneralActions() {
  yield takeEvery('CHECK_MAINTENANCE_MODE_STATUS', beginCheckMaintenanceModeStatus);
  yield takeEvery('REGISTER_DEVICE_FCM_TOKEN_REQUESTED', beginRegisterFcmToken);
  yield takeEvery('UNREGISTER_DEVICE_FCM_TOKEN_REQUESTED', beginUnregisterFcmToken);
  yield takeEvery('ADD_GLOBAL_MESSAGE_REQUESTED', beginAddGlobalMessage)
}

function* beginCheckMaintenanceModeStatus() {
  try {
    const request = yield call(() => basicAPIRequest("/general/check-status", { }));
    if (request.statusCode !== statusCodes.ONGOING_MAINTENANCE) yield put(setMaintenanceMode(false));
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginRegisterFcmToken(action) {
  try {
    const { token: fcmToken } = action;
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/store/register-push-token", { token: fcmToken, type: 1 }, token)); // FCM = 1

    yield genericHandleResult(
      request.statusCode,
      null,
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginUnregisterFcmToken(action) {
  try {
    const { token: fcmToken } = action;
    const token = yield select(state => state.authentication.token);
    const request = yield call(() => authAPIRequest("/store/unregister-push-token", { token: fcmToken }, token));

    yield genericHandleResult(
      request.statusCode,
      null,
      addGlobalMessageRequested({ text: request.errorMsg, type: notificationTypes.ERROR })
    );
  } catch (e) {
    yield put(addGlobalMessageRequested({ text: e.message, type: notificationTypes.ERROR }));
  }
}

function* beginAddGlobalMessage(action) {
  const { message } = action;
  const id = Math.random().toString(36).substr(2, 12);
  yield put(addGlobalMessage({ id, ...message }));

  if (message.duration) {
    yield delay(message.duration);
    yield put(removeGlobalMessage(id));
  }
}