import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { retrieveDoneOrdersRequested, retrievePackedOrdersRequested } from "../actions/order";
import { ActivityIndicator } from "../components/loading/ActivityIndicator";
import { OrderDetails } from "../components/order/OrderDetails";
import { OrderEntry } from "../components/order/OrderEntry";
import { OrderTabs } from "../components/order/OrderTabs";
import { DataAgeDisplay } from "../components/text/DataAgeDisplay";
import { OrderTabContext } from "../helpers/contexts";
import { compareDateTimes } from "../utils/datetime";
import "./../style/routes/Order.scss";

let requestPending = false;

export const Orders = ({ match }) => {
  const [activeTab, setActiveTab] = useState(1);
  const [lastId, setLastId] = useState(null);
  const [bottomReached, setBottomReached] = useState(false);
  const openOrders = useSelector(state => state.order.openOrders);
  const packedOrders = useSelector(state => state.order.packedOrders);
  const doneOrders = useSelector(state => state.order.doneOrders);
  const dispatch = useDispatch();

  const selectedOrderId = parseInt(match.params.selectedOrderId);

  useEffect(() => {
  //   dispatch(retrieveOpenOrdersRequested()); // These are requested in AppContainer in order to be able to always display the openOrderAmount
    dispatch(retrievePackedOrdersRequested());
    dispatch(retrieveDoneOrdersRequested(null, handleResponse));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  // Old implementation that requests tab contents when selected
  // useEffect(() => {
  //   const info = getInfoForTab(activeTab);
  //   if (!info.list) dispatch(info.action);
  // }, [activeTab]); // eslint-disable-line react-hooks/exhaustive-deps

  // const getInfoForTab = () => {
  //   switch (activeTab) {
  //     case 1: {
  //       return { list: openOrders, action: retrieveOpenOrdersRequested(), bottomReached: true };
  //     }
  //     case 2: {
  //       return { list: packedOrders, action: retrievePackedOrdersRequested(), bottomReached: true };
  //     }
  //     case 3: {
  //       return { list: doneOrders, action: retrieveDoneOrdersRequested(null, handleResponse), bottomReached: bottomReached };
  //     }
  //     default: {
  //       return null;
  //     }
  //   }
  // }

  const getInfoForTab = () => {
    switch (activeTab) {
      case 1: {
        return { list: openOrders, sortItems: false, bottomReached: true };
      }
      case 2: {
        return { list: packedOrders, sortItems: false, bottomReached: true };
      }
      case 3: {
        return { list: doneOrders, sortItems: false, bottomReached: bottomReached };
      }
      default: {
        return null;
      }
    }
  }

  const handleResponse = (orderAmount, lastId) => {
    if (orderAmount < 10) setBottomReached(true);
    else setLastId(lastId);
    requestPending = false;
  }

  const handleScroll = (e) => {
    if (requestPending || bottomReached || activeTab !== 3) return;
    const { offsetHeight, scrollTop, scrollHeight } = e.target;
    if (offsetHeight + scrollTop + 200 >= scrollHeight) {
      requestPending = true;
      dispatch(retrieveDoneOrdersRequested(lastId, handleResponse));
    }
  }

  const currentTabInfo = getInfoForTab(activeTab);
  const orderList = currentTabInfo && currentTabInfo.list;
  if (currentTabInfo.sortItems && orderList) orderList.sort((a, b) => compareDateTimes(a.executionTime, b.executionTime));
  const orderListComps = orderList && orderList.map((order) => <OrderEntry key={order.id} selectedOrderId={selectedOrderId} {...order} />);

  return (
    <div className="appContent">
      <div className="pageHeader">
        <div className="pageHeaderLeft">
          <span className="pageTitle">Bestellungsübersicht</span>
          <span className="pageDescription">Alle eingegangenen Bestellungen werden hier aufgelistet</span>
        </div>
        <div className="pageHeaderRight noMarginTop">
          <DataAgeDisplay />
        </div>
      </div>
      <div className="pageContent">
        <div className="card maxHeight leftList">
          <OrderTabContext.Provider value={{ activeTab, setActiveTab }}>
            <OrderTabs openOrderAmount={openOrders && openOrders.length} packedOrderAmount={packedOrders && packedOrders.length} />
            <div className="listContent" onScroll={handleScroll}>
              {orderListComps}
              {(!currentTabInfo.list || !currentTabInfo.bottomReached) && <ActivityIndicator />}
              {(currentTabInfo.list && currentTabInfo.list.length === 0) && <span className="emptyText">Keine Bestellungen gefunden</span>}
            </div>
          </OrderTabContext.Provider>
        </div>
        <div className="card maxHeight overflowY">
          {selectedOrderId ? <OrderDetails selectedOrderId={selectedOrderId} /> : <span className="emptyText">Bitte wähle eine Bestellung aus</span>}
        </div>
      </div>
    </div>
  )
}