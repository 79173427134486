const initialState = {
  id: 0,
  name: "",
  urlIdentifier: "",
  colors: {
    primary: "",
    onPrimary: ""
  },
  storeId: null, // This is in here because it needs to be persisted in storage 
};

export const company = (state = initialState, action) => {
  switch (action.type) {
    case "SET_STORE_ID": {
      return {
        ...state,
        storeId: action.id
      }
    }
    case "SET_COMPANY_INFOS": {
      const { id, name, colors, urlIdentifier } = action;
      return {
        ...state,
        id,
        name,
        colors,
        urlIdentifier
      }
    }
    default:
      return state;
  }
};
