export const getCategoriesRequested = () => ({
  type: "GET_CATEGORIES_REQUESTED"
});

export const getCategoriesSucceeded = (categoryList) => ({
  type: "GET_CATEGORIES_SUCCEEDED",
  categoryList
});

export const retrieveProductsRequested = () => ({
  type: "RETRIEVE_PRODUCTS_REQUESTED"
});

export const retrieveProductsSucceeded = (productList) => ({
  type: "RETRIEVE_PRODUCTS_SUCCEEDED",
  productList
});

export const retrieveOfferedProductsRequested = () => ({
  type: "RETRIEVE_OFFERED_PRODUCTS_REQUESTED"
});

export const retrieveOfferedProductsSucceeded = (productList) => ({
  type: "RETRIEVE_OFFERED_PRODUCTS_SUCCEEDED",
  productList
});

export const setOfferedProductMapping = (productMapping) => ({
  type: "SET_OFFERED_PRODUCT_MAPPING",
  productMapping
});

export const addToOfferedProductsRequested = (productId, dailyAmountAvailable, handleResponse) => ({
  type: "ADD_TO_OFFERED_PRODUCTS_REQUESTED",
  productId, dailyAmountAvailable, handleResponse
});

export const addToOfferedProductsSucceeded = (productId, dailyAmountAvailable) => ({
  type: "ADD_TO_OFFERED_PRODUCTS_SUCCEEDED",
  productId, dailyAmountAvailable
});

export const removeFromOfferedProductsRequested = (productId, handleResponse) => ({
  type: "REMOVE_FROM_OFFERED_PRODUCTS_REQUESTED",
  productId, handleResponse
});

export const removeFromOfferedProductsSucceeded = (productId) => ({
  type: "REMOVE_FROM_OFFERED_PRODUCTS_SUCCEEDED",
  productId
});