import React from "react";
import { useSelector } from "react-redux";
import { Feather as Icon } from 'react-web-vector-icons';

export const OfflineIndicator = () => {
  const networkOffline = useSelector(state => state.general.networkOffline);

  return networkOffline ? (
    <div className="offlineIndicator">
      <div className="innerContainer">
        <div className="top">
          <Icon name="wifi-off" color='var(--onError)' size={24} />
          <span>Keine Netzwerkverbindung</span>
        </div>
        <div className="bottom">
          <span>Neue Bestellungen können nicht angezeigt werden.</span>
        </div>
      </div>
    </div>
  ) : null;
}