import { DateTime } from "luxon";

const initialState = {
  notifications: [
    // { id: 4, text: "Ich bin ein Fehler is dat kla", type: 3 },
  ],
  maintenanceMode: false,
  networkOffline: false,
  lastNetworkStateUpdate: null,
  dataIntegrityGuaranteed: true,
  openModal: null,
  modalParams: {}
};

export const general = (state = initialState, action) => {
  switch (action.type) {
    case "SET_NETWORK_OFFLINE": {
      let diffInSecs = { seconds: 0 };
      if (state.lastNetworkStateUpdate) {
        const lastUpdate = DateTime.fromISO(state.lastNetworkStateUpdate);
        const now = DateTime.now();
        diffInSecs = now.diff(lastUpdate, 'seconds');
      }
      return {
        ...state,
        networkOffline: action.value,
        lastNetworkStateUpdate: DateTime.now().toISO(),
        dataIntegrityGuaranteed: action.value === false && diffInSecs.seconds > 3 ? false : state.dataIntegrityGuaranteed
      }
    }
    case "SET_MAINTENANCE_MODE": {
      return {
        ...state,
        maintenanceMode: action.value
      }
    }
    case "REMOVE_GLOBAL_MESSAGE": {
      const newNotifications = state.notifications.filter(n => n.id !== action.id);
      return {
        ...state,
        notifications: newNotifications
      }
    }
    case "ADD_GLOBAL_MESSAGE": {
      return {
        ...state,
        notifications: [
          ...state.notifications,
          action.message
        ]
      }
    }
    case "SET_OPEN_MODAL": {
      return {
        ...state,
        openModal: action.value,
        modalParams: action.params ? action.params : {}
      }
    }
    default:
      return state;
  }
};
