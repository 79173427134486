import firebase from 'firebase/app';
import 'firebase/messaging';
import { deleteFCMToken, setFCMToken } from './actions/authentication';
import { registerDeviceFcmToken, unregisterDeviceFcmToken } from './actions/general';

// var firebaseConfig = {
//   apiKey: "AIzaSyArRqGXt02_mIcbzbMg4sCIK_AspfUQFoQ",
//   authDomain: "brotbox-82fee.firebaseapp.com",
//   projectId: "brotbox-82fee",
//   storageBucket: "brotbox-82fee.appspot.com",
//   messagingSenderId: "346605586966",
//   appId: "1:346605586966:web:586f940c99450c95cd4337"
// };

var firebaseConfig = {
  apiKey: process.env.REACT_APP_FCM_API_KEY,
  authDomain: process.env.REACT_APP_FCM_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FCM_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FCM_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FCM_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FCM_APP_ID
};

let messaging;
if (firebase.messaging.isSupported())	{
  firebase.initializeApp(firebaseConfig);
  messaging = firebase.messaging();
} else {
  console.log("No support for Web Push API");
}

export const getFCMToken = (dispatch, setLoading) => {
  if (setLoading) setLoading(true);

  const firebaseUrlConfig = new URLSearchParams({
    apiKey: process.env.REACT_APP_FCM_API_KEY,
    authDomain: process.env.REACT_APP_FCM_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FCM_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FCM_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FCM_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FCM_APP_ID
  }).toString();

  navigator.serviceWorker.register(`/firebase-messaging-sw.js?${firebaseUrlConfig}`).then((swRegistration) => {
    messaging.getToken({ vapidKey: process.env.REACT_APP_FCM_VAPID_KEY, serviceWorkerRegistration: swRegistration }).then((currentToken) => {
      if (currentToken) {
        console.log('current token for client: ', currentToken);
        dispatch(setFCMToken(currentToken));
        dispatch(registerDeviceFcmToken(currentToken));
      } else {
        dispatch(deleteFCMToken());
      }
      if (setLoading) setLoading(false);
    }).catch((err) => {
      console.log('An error occurred while retrieving token. ', err);
    });
  }).catch((err) => {
    console.log('An error occurred while registering service worker ', err);
  });
}

export const deleteFCMRegistrationToken = (dispatch, setLoading, fcmToken) => {
  if (setLoading) setLoading(true);
  messaging.deleteToken().then(() => {
    dispatch(unregisterDeviceFcmToken(fcmToken));
    dispatch(deleteFCMToken());
    if (setLoading) setLoading(false);
  }).catch((err) => {
    console.log('An error occurred while deleting token. ', err);
  });
}