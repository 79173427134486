import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStoreTagsRequested, updateOpeningHoursRequested, updateOrderTimeSettingsRequested, updatePhoneNumberRequested } from "../actions/store";
import { DefaultButton } from "../components/buttons/DefaultButton";
import { DefaultInput } from "../components/inputs/DefaultInput";
import { OpeningHoursInput } from "../components/inputs/OpeningHoursInput";
import { ActivityIndicator } from "../components/loading/ActivityIndicator";
import "./../style/routes/Settings.scss";
import { Duration } from 'luxon';
import { SpecialHoursList } from "../components/Settings/SpecialHoursList";
import { AddSpecialHours } from "../components/Settings/AddSpecialHours";
import { deleteFCMRegistrationToken, getFCMToken } from "../firebase";
import { SelectionInput } from "../components/inputs/SelectionInput";
import { orderAcceptanceModeData, orderAcceptanceModeDetails, orderAcceptanceModes } from "../data/orderAcceptanceModes";
import { StoreTagList } from "../components/Settings/StoreTagList";

export const Settings = () => {
  const openingHours = useSelector(state => state.store.openingHours);
  const [newOpeningHours, setNewOpeningHours] = useState(null);
  const [openingHoursLoading, setOpeningHoursLoading] = useState(false);
  const [openingHoursError, setOpeningHoursError] = useState(null);
  const [openingHoursSuccess, setOpeningHoursSuccess] = useState(null);

  const phoneNumber = useSelector(state => state.store.phoneNumber);
  const [newPhoneNumber, setNewPhoneNumber] = useState(null);
  const [phoneNumberLoading, setPhoneNumberLoading] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(null);
  const [phoneNumberSuccess, setPhoneNumberSuccess] = useState(null);

  const [showAddSpecialHours, setShowAddSpecialHours] = useState(false);

  const fcmToken = useSelector(state => state.authentication.fcmToken);
  const [fcmLoading, setFcmLoading] = useState(false);

  const orderTimeSettings = useSelector(state => state.store.orderTimeSettings);
  const [newOrderAcceptanceMode, setNewOrderAcceptanceMode] = useState(-1);
  const [newMinMinsBeforeOrder, setNewMinMinsBeforeOrder] = useState(30);
  const [newUntilTime, setNewUntilTime] = useState("18:00");
  const [newDaysBefore, setNewDaysBefore] = useState(1);
  const [orderTimeSettingsLoading, setOrderTimeSettingsLoading] = useState(false);
  const [orderTimeSettingsSuccess, setOrderTimeSettingsSuccess] = useState(null);
  const [orderTimeSettingsError, setOrderTimeSettingsError] = useState(null);

  const availableStoreTags = useSelector(state => state.store.availableStoreTags);
  const activeStoreTags = useSelector(state => state.store.activeStoreTags);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getStoreTagsRequested());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (openingHours) setNewOpeningHours(openingHours);
  }, [openingHours]);

  useEffect(() => {
    if (phoneNumber) setNewPhoneNumber(phoneNumber);
  }, [phoneNumber]);

  useEffect(() => {
    if (orderTimeSettings) {
      const { orderAcceptanceMode, minMinsBeforeOrder, untilTime, daysBefore } = orderTimeSettings;
      setNewOrderAcceptanceMode(orderAcceptanceMode);
      setNewMinMinsBeforeOrder(minMinsBeforeOrder);
      setNewUntilTime(untilTime);
      setNewDaysBefore(daysBefore);
    }
  }, [orderTimeSettings]);

  const updateOpeningHours = () => {
    setOpeningHoursLoading(true);
    setOpeningHoursError(null);
    setOpeningHoursSuccess(null);

    // Check if openTime < closeTime
    let validOpeningHours = true;
    newOpeningHours.forEach(h => {
      if (!validOpeningHours) return;
      const openTime = Duration.fromISOTime(h.openTime).toMillis();
      const closeTime = Duration.fromISOTime(h.closeTime).toMillis();
      const valid = openTime < closeTime; // Also returns false if invalid time
      if (!valid) validOpeningHours = false;
    });

    if (!validOpeningHours) {
      setOpeningHoursLoading(false);
      setOpeningHoursError("Ungültige Öffnungszeiten. Bitte überprüfe die angegebenen Öffnungszeiten.");
      return;
    }

    dispatch(updateOpeningHoursRequested(newOpeningHours, (success, errorMsg) => {
      setOpeningHoursLoading(false);
      if (success) {
        setOpeningHoursSuccess("Öffnungszeiten erfolgreich angepasst!");
        setTimeout(() => setOpeningHoursSuccess(null), 3000);
      } else setOpeningHoursError(errorMsg);
    }));
  }

  const updatePhoneNumber = () => {
    setPhoneNumberLoading(true);
    setPhoneNumberError(null);
    setPhoneNumberSuccess(null);
    dispatch(updatePhoneNumberRequested(newPhoneNumber, (success, errorMsg) => {
      setPhoneNumberLoading(false);
      if (success) {
        setPhoneNumberSuccess("Kontaktdaten erfolgreich angepasst!");
        setTimeout(() => setPhoneNumberSuccess(null), 3000);
      } else setPhoneNumberError(errorMsg);
    }));
  }

  const updateOrderTimeSettings = () => {
    setOrderTimeSettingsLoading(true);
    setOrderTimeSettingsSuccess(null);
    setOrderTimeSettingsError(null);

    // Validate orderAcceptanceMode
    const { BASIC_GUARANTEE, ALWAYS_PROVISIONALLY, GUARANTEE_OR_NONE, PROVISIONALLY_OR_NONE } = orderAcceptanceModes;
    const possibleModes = [BASIC_GUARANTEE, ALWAYS_PROVISIONALLY, GUARANTEE_OR_NONE, PROVISIONALLY_OR_NONE];
    if (possibleModes.indexOf(newOrderAcceptanceMode) === -1) {
      setOrderTimeSettingsLoading(false);
      setOrderTimeSettingsError("Bitte wähle eine gültige Variante der Bestellungsannahme.");
      return;
    }

    // Validate untilTime
    const untilTimeDur = Duration.fromISOTime(newUntilTime);
    if (!untilTimeDur.isValid) {
      setOrderTimeSettingsLoading(false);
      setOrderTimeSettingsError("Die angegebene Uhrzeit ist ungültig. Bitte überprüfe deine Eingabe.");
      return;
    }

    // (rest isn't prone to errors and can be checked by the backend)

    dispatch(updateOrderTimeSettingsRequested({
      orderAcceptanceMode: newOrderAcceptanceMode,
      minMinsBeforeOrder: parseInt(newMinMinsBeforeOrder),
      untilTime: newUntilTime,
      daysBefore: parseInt(newDaysBefore)
    }, (success, errorMsg) => {
      setOrderTimeSettingsLoading(false);
      if (success) {
        setOrderTimeSettingsSuccess("Bestellzeiten erfolgreich angepasst!");
        setTimeout(() => setOrderTimeSettingsSuccess(null), 3000);
      } else setOrderTimeSettingsError(errorMsg);
    }));
  }

  return (
    <div className="appContent allowVerticalScroll">
      <div className="pageHeader">
        <div className="pageHeaderLeft">
          <span className="pageTitle">Einstellungen zur Filiale</span>
          <span className="pageDescription">Hier können Einstellungen zu dieser Filiale angepasst und konfiguriert werden.</span>
        </div>
      </div>
      <div className="pageContent settings">
        <div className="cardContainer leftView sideCard">
          <div className="card defaultPadding fitHeight topCard dynamicSize">
            <span className="large title">Benachrichtigungen</span>
            <div><span>Durch das Aktivieren der Benachrichtigungen, erhalten Sie unmittelbar beim Eintreffen einer neuen Bestellung eine Benachrichtigung. (Nicht unterstützt auf iOS)</span></div>
            <span style={{ marginTop: 12, display: 'inline-block' }}>Web-Push-Status: {fcmToken ? <span className="success">Aktiviert</span> : <span className="error">Deaktiviert</span>}</span>
            {fcmToken ?
              <DefaultButton text="Deaktivieren" loading={fcmLoading} onPress={() => deleteFCMRegistrationToken(dispatch, setFcmLoading, fcmToken)} />
              :
              <DefaultButton text="Aktivieren" loading={fcmLoading} onPress={() => getFCMToken(dispatch, setFcmLoading)} />}
          </div>
          <div className="card defaultPadding fitHeight topCard dynamicSize">
            {openingHours && newOpeningHours ? (
              <React.Fragment>
                <span className="large title">Öffnungszeiten</span>
                <OpeningHoursInput data={newOpeningHours} onChange={(nd) => setNewOpeningHours(nd)} />
                {openingHoursSuccess ? <span className="success">{openingHoursSuccess}</span> : null}
                {openingHoursError ? <span className="error">{openingHoursError}</span> : null}
                <DefaultButton text="Speichern" loading={openingHoursLoading} onPress={() => updateOpeningHours()} />
              </React.Fragment>
            ) : <ActivityIndicator />}
          </div>
          <div className="card defaultPadding fitHeight topCard dynamicSize">
            {phoneNumber && newPhoneNumber !== null ? (
              <React.Fragment>
                <span className="large title">Kontaktdaten</span>
                <DefaultInput name="Telefon" value={newPhoneNumber} onChange={(e) => setNewPhoneNumber(e.target.value)} />
                {phoneNumberSuccess ? <span className="success">{phoneNumberSuccess}</span> : null}
                {phoneNumberError ? <span className="error">{phoneNumberError}</span> : null}
                <DefaultButton text="Speichern" loading={phoneNumberLoading} onPress={() => updatePhoneNumber()} />
              </React.Fragment>
            ) : <ActivityIndicator />}
          </div>
          <div className="card defaultPadding fitHeight dynamicSize">
            {availableStoreTags && activeStoreTags ? (
              <React.Fragment>
                <span className="large title">Besonderheiten</span>
                <StoreTagList availableStoreTags={availableStoreTags} activeStoreTags={activeStoreTags} />
              </React.Fragment>
            ) : <ActivityIndicator />}
          </div>
        </div>
        <div className="cardContainer sideCard">
          <div className="card defaultPadding topCard dynamicSize">
            {orderTimeSettings ? (
              <React.Fragment>
                <span className="large title">Einschränkung der Bestellzeiten</span>
                <SelectionInput name="Variante der Bestellungsannahme" data={orderAcceptanceModeData} value={newOrderAcceptanceMode} onChange={e => setNewOrderAcceptanceMode(parseInt(e.target.value))} />
                {newOrderAcceptanceMode !== -1 && <span style={{ marginBottom: 16, display: 'inline-block' }}>{orderAcceptanceModeDetails[newOrderAcceptanceMode].desc}</span>}
                {newOrderAcceptanceMode !== 2 && <React.Fragment>
                  <DefaultInput name="Tage zuvor" type="number" min={1} max={7} value={newDaysBefore} onChange={(e) => setNewDaysBefore(e.target.value)} />
                  <DefaultInput name="Uhrzeit" value={newUntilTime} onChange={(e) => setNewUntilTime(e.target.value)} />
                </React.Fragment>}
                <DefaultInput name="Mindestzeit zwischen Bestellungsaufgabe und Abholung in Minuten (unabhängig)" min={0} type="number" value={newMinMinsBeforeOrder} onChange={(e) => setNewMinMinsBeforeOrder(e.target.value)} />
                
                {orderTimeSettingsSuccess ? <span className="success">{orderTimeSettingsSuccess}</span> : null}
                {orderTimeSettingsError ? <span className="error">{orderTimeSettingsError}</span> : null}
                <DefaultButton text="Speichern" loading={orderTimeSettingsLoading} onPress={() => updateOrderTimeSettings()} />
              </React.Fragment>
            ) : <ActivityIndicator />}
          </div>
          <div className="card defaultPadding dynamicSize">
            <span className="large title">Sonderfälle der Öffnungszeiten</span>
            <SpecialHoursList />
            {!showAddSpecialHours && <DefaultButton text="Neuen Sonderfall anlegen" onPress={() => setShowAddSpecialHours(true)} />}
            {showAddSpecialHours && (
              <React.Fragment>
                <span className="large title">Neuen Sonderfall anlegen</span>
                <AddSpecialHours />
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}