import React, { useEffect, useState } from "react";
import { Sidemenu } from "../../components/sidemenu/Sidemenu";
import CacheRoute from 'react-router-cache-route';
import { useDispatch, useSelector } from "react-redux";
import { Orders } from "../Orders";
import { Products } from "../Products";
import "./../../style/Global.scss";
import { shadeColor } from "../../utils/color";
import { Customers } from "../Customers";
import { Support } from "../Support";
import { Settings } from "../Settings";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import { listenForSocketEvents } from "../../socketio";
import { Settings as LuxonSettings } from 'luxon';
import { getCategoriesRequested } from "../../actions/product";
import { insertNewOrder, retrieveOpenOrdersRequested } from "../../actions/order";
import { OfflineIndicator } from "../../components/general/OfflineIndicator";
import { NotificationHandler } from "../../components/general/NotificationHandler";
import { getStoreDetailsRequested } from "../../actions/store";
import { setDynamicManifest } from "../../data/dynamicManifest";
import { useQuery } from "../../hooks/useQuery";
import { RejectionReasonModal } from "../../components/modal/RejectionReasonModal";

export const AppContainer = () => {
  const { isTablet } = useWindowDimensions();
  const [sidemenuCollapsed, setSidemenuCollapsed] = useState(isTablet);
  const token = useSelector(state => state.authentication.token);
  const colors = useSelector(state => state.company.colors);
  const urlIdentifier = useSelector(state => state.company.urlIdentifier);
  const dispatch = useDispatch();
  const query = useQuery();

  useEffect(() => {
    LuxonSettings.defaultZoneName = "Europe/Berlin";
    dispatch(getCategoriesRequested());
    listenForSocketEvents(token, dispatch);

    // Request open orders here to display openOrderAmount even if other route loaded
    dispatch(retrieveOpenOrdersRequested());
    dispatch(getStoreDetailsRequested());

    // Notification handler when app is focused (handled by socketio now)
    // messaging.onMessage((payload) => {

    // });

    // Notification handler when app is in background
    if (navigator && navigator.serviceWorker) { // serviceWorker is undefined on iOS
      navigator.serviceWorker.addEventListener('message', (event) => {
        const { id, pickupCode, executionTime, currentOrderState } = event.data;
        if (!id) return; // All data is undefined sometimes when called twice (inconsistent but e.g. click on notification after site opened)
        dispatch(insertNewOrder({ id, pickupCode, executionTime, currentOrderState: parseInt(currentOrderState) }));
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSidemenuCollapsed(isTablet);
  }, [isTablet]);

  useEffect(() => {
    document.documentElement.style.setProperty("--primary", colors.primary);
    document.documentElement.style.setProperty("--primaryLight", shadeColor(colors.primary, 20));
    document.documentElement.style.setProperty("--primaryVeryLight", shadeColor(colors.primary, 40));
    document.documentElement.style.setProperty("--onPrimary", colors.onPrimary);
  }, [colors]);

  useEffect(() => {
    setDynamicManifest(urlIdentifier);
  }, [urlIdentifier]);

  return (
    <div className={`appWrapper ${sidemenuCollapsed ? 'sidemenuCollapsed' : ''} ${query.get('hidemenu') === 'true' ? 'sidemenuHidden' : ''}`}>
      <Sidemenu sidemenuCollapsed={sidemenuCollapsed} setSidemenuCollapsed={setSidemenuCollapsed} isTablet={isTablet} />
      <NotificationHandler />
      <OfflineIndicator />
      <CacheRoute path={["/orders", "/order/:selectedOrderId"]} component={Orders} />
      <CacheRoute path={["/products", "/product/:id"]} component={Products} />
      <CacheRoute path={["/customers", "/customer/:id"]} component={Customers} />
      <CacheRoute path={["/support"]} component={Support} />
      <CacheRoute path={["/settings"]} component={Settings} />
      <RejectionReasonModal />
    </div>
  )
}