import React from "react";
import { useDispatch } from "react-redux";
// import { useHistory } from "react-router-dom";
import { selectCustomer } from "./../../actions/customer";

export const CustomerEntry = ({ id, firstName, lastName }) => {
  // const history = useHistory();
  const dispatch = useDispatch();

  const selectCustomerEntry = () => {
    dispatch(selectCustomer(id));

    // Not using urls because all customer information is derived from list data
    // and has no data if page is refreshed
    // history.push("/customer/" + id);
  }

  return (
    <div className="minimalListElement" onClick={() => selectCustomerEntry()}>
      <span>{firstName + " " + lastName}</span>
    </div>
  )
}